import {Box, styled} from '@mui/material'
import React from 'react'
import OutreachIcon from '../../images/icons/outreach-icon'

const RepresentativePhoto: React.FC<{
  size: number
  photo?: string
}> = ({size, photo}) => {
  return (
    <StyledPhoto size={size}>
      {photo ? (
        <img
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
          src={photo}
        />
      ) : (
        <OutreachIcon />
      )}
    </StyledPhoto>
  )
}

const StyledPhoto = styled(Box)<{size: number}>`
  ${({theme, size}) => `
  width: ${theme.spacing(size || 6)};
  height: ${theme.spacing(size || 6)};
  background: ${theme.palette.secondary.light};
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`}
`

export default RepresentativePhoto
