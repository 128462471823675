import { Badge, Box, CircularProgress, IconButton, Typography, styled } from '@mui/material'
import * as React from 'react'
import { colors } from '../constants/colors'
import { actionDataState } from '../context/action-data-context'
import { candidateDataState } from '../context/candidate-data-context'
import { resultsState } from '../context/results-context'
import FilterIcon from '../images/icons/filter-icon'
import FilterControls from './filter-controls'
import FullScreenModal from './full-screen-modal'
import { locationState } from '../context/location-context'
import LocationControls from './location-controls'
import { PlaceOutlined } from '@mui/icons-material'

const LocationBox = () => {
  const { settings, locationLoading } = locationState()
  const { address, prioritizeLocation, restrictToLocation } = settings

  const [showFilterModal, setShowFilterModal] = React.useState(false)
  const closeModal = () => {
    setShowFilterModal(false)
  }

  const filterCount = [address, prioritizeLocation, restrictToLocation].filter(
    exists => exists
  ).length

  return (
    <LocationBoxElement>
      <Typography
        flex={1}
        color="textSecondary"
        variant="body2"
        textAlign="center"
        sx={{ opacity: settings.restrictToLocation ? 1 : 0.5 }}
      >
        {locationLoading ? (
          <CircularProgress size={12} color="secondary" />
        ) : (
          <React.Fragment>{address || 'Anywhere'}</React.Fragment>
        )}
      </Typography>
      <StyledBadge color="primary" badgeContent={filterCount}>
        <StyledIconButton
          sx={{ width: 32, height: 32 }}
          aria-label={`Location Filters`}
          onClick={() => setShowFilterModal(true)}
        >
          <PlaceOutlined sx={{ color: 'currentcolor' }} fontSize="medium" />
        </StyledIconButton>
      </StyledBadge>

      <FullScreenModal
        open={showFilterModal}
        colorScheme="grey"
        contentPadding={false}
        onClose={closeModal}
      >
        <LocationControls onSave={closeModal} />
      </FullScreenModal>
    </LocationBoxElement>
  )
}

const StyledBadge = styled(Badge)`
  pointer-events: none;
`

const StyledIconButton = styled(IconButton)`
  ${({ theme }) => {
    const background = theme.palette.secondary.main
    const color = colors.WHITE
    const padding = theme.spacing(0.5)
    return `
      background-color: ${background};
      border-radius: 9px;
      pointer-events: all;
      color: ${color};
      padding: ${padding};
      &:hover {
        background-color: ${background};
      };
  `
  }}
`

const LocationBoxElement = styled(Box)`
  ${({ theme }) => {
    const paddingLeft = theme.spacing(1.5)
    return `
        border-radius: 9px;
        display: inline-flex;
        align-items: center;
        white-space: nowrap;
        gap: ${paddingLeft};
        padding: 0 0 0 ${paddingLeft};

        background: ${theme.palette.mode == 'dark' ? colors.DARK_MODE_70 : colors.WHITE};
    `
  }}
`

export default LocationBox
