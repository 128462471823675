import React from 'react'

const Sailboat = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="101"
    height="94"
    viewBox="0 0 101 94"
    fill="none"
  >
    <path
      d="M100.495 63.4219C98.9552 66.7688 97.0758 69.949 94.8868 72.9126C90.5449 78.8567 83.679 85.814 74.2891 88.0706L74.1249 88.1105C64.9368 87.6836 58.221 84.3245 58.221 84.3245C47.7685 92.9051 38.0948 86.9775 38.0948 86.9775C22.8054 96.3393 14.5368 86.9775 14.5368 86.9775L8.83433 89.2576L5.16331 84.7491L0.5 79.0209L100.495 63.4219Z"
      fill="#222222"
      stroke="#222222"
      strokeWidth="1.88754"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M74.125 88.1149C78.8353 88.3586 83.5492 87.7099 88.0187 86.2031"
      stroke="#222222"
      strokeWidth="1.88754"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.83662 89.2578L0.5 92.5934"
      stroke="#222222"
      strokeWidth="1.88754"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.3003 55.9131C51.4642 55.9131 63.7569 43.6204 63.7569 28.4566C63.7569 13.2927 51.4642 1 36.3003 1C21.1365 1 8.84375 13.2927 8.84375 28.4566C8.84375 43.6204 21.1365 55.9131 36.3003 55.9131Z"
      fill="white"
      stroke="#222222"
      strokeWidth="1.88754"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.9311 72.5556L32.7969 9.90625"
      stroke="#222222"
      strokeWidth="1.88754"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.7178 18.2898L16.1719 16.9481L32.4957 9.90625L33.7178 18.2898Z"
      fill="#FF4438"
      stroke="#222222"
      strokeWidth="1.88754"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.7188 18.2938C33.7188 18.2938 57.4879 51.9595 41.164 67.284C41.164 67.284 75.6859 69.9746 74.1331 51.4083C72.5802 32.8419 52.2499 17.3157 33.7188 18.2938Z"
      fill="#6FB1C8"
      stroke="#222222"
      strokeWidth="1.88754"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.1431 18.25L37.3632 68.7274L21.5625 71.0661L30.1431 18.25Z"
      fill="#6FB1C8"
      stroke="#222222"
      strokeWidth="1.88754"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M100.495 63.4219C98.9552 66.7688 97.0758 69.949 94.8868 72.9126L5.16331 84.7491L0.5 79.0209L100.495 63.4219Z"
      fill="white"
      stroke="#222222"
      strokeWidth="1.88754"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Sailboat
