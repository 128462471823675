import { Backdrop, CircularProgress } from '@mui/material'
import * as React from 'react'
const LoadingScreen: React.FC<{ loading: boolean; hideProgress?: boolean }> = ({
  loading,
  hideProgress
}) => {
  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: theme => theme.zIndex.drawer + 1
      }}
      open={loading}
    >
      {!hideProgress && <CircularProgress color="inherit" />}
    </Backdrop>
  )
}

export default LoadingScreen
