import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
  styled
} from '@mui/material'
import * as React from 'react'
import { colors } from '../../constants/colors'
import { BlueBox, HBox, PillSmall, SquareButton, VBox } from '../../elements/basic-elements'
import { ScrollCard } from '../../elements/cards'
import { T3 } from '../../elements/text-elements'
import ArrowRight from '../../images/icons/arrow-right'
import HeartIcon from '../../images/icons/heart-icon'
import { getBeHeardLocationStringFromSanityLocation, getLocationType } from '../../utils/data'
import { blocksToText, getUniqueCobenefits } from '../../utils/sanity'

import { Check, InfoOutlined, OpenInNew, WarningOutlined } from '@mui/icons-material'
import { locationState } from '../../context/location-context'
import DonateIcon from '../../images/icons/donate-icon'
import FundIcon from '../../images/icons/fund-icon'
import GlobeIcon from '../../images/icons/globe-icon'
import HeartIconFilled from '../../images/icons/heart-icon-filled'
import HelpIcon from '../../images/icons/help-icon'
import InvestIcon from '../../images/icons/invest-icon'
import LearnIcon from '../../images/icons/learn-icon'
import OutreachIcon from '../../images/icons/outreach-icon'
import PurchaseIcon from '../../images/icons/purchase-icon'
import VolunteerIcon from '../../images/icons/volunteer-icon'
import { authState } from '../../state/auth-state'
import { RepresentativeMap, RepresentativesObject } from '../../types/location-types'
import { createEmptyArray, extractSimpleUrl } from '../../utils'
import CenteredModal from '../centered-modal'
import ConfirmActionCard from '../confirm-action-card'
import FullScreenModal from '../full-screen-modal'
import { ShareButtonIcon } from '../share-button'
import SignupWarningCard from '../signup-warning-card'
import TooltipTitle from '../tooltip-title'
import RepCard, { RepCardData } from './rep-card'
import RepresentativeButton from './representative-button'

type ResultCardData = {
  location: Queries.Maybe<Queries.SanityLocation>
  organization: Queries.Maybe<Queries.SanityOrganization>
  title: Queries.Maybe<string>
  rank: Queries.Maybe<number>
  description?: Queries.Maybe<readonly Queries.Maybe<Queries.SanityBlock>[]>
  tags: Queries.Maybe<readonly Queries.Maybe<Queries.SanityTag>[]>
  nouns: Queries.Maybe<readonly Queries.Maybe<Queries.SanityNoun>[]>
  slug: Queries.Maybe<Queries.SanitySlug>
  categories: Queries.Maybe<readonly Queries.Maybe<Queries.SanityCategory>[]>
  outreach: Queries.Maybe<Queries.SanityOutreach>
  url: Queries.Maybe<string>
  shareUrl?: string
}

const iconsByCategory = {
  'get-educated': <LearnIcon />,
  volunteer: <VolunteerIcon />,
  purchase: <PurchaseIcon />,
  invest: <InvestIcon />,
  'get-help': <HelpIcon />,
  'get-funded': <FundIcon />,
  fundraise: <DonateIcon />,
  donate: <DonateIcon />,
  'be-heard': <OutreachIcon />
}

const ctaLabelsByCategory = {
  'be-heard': 'Learn More'
}

type ResultCardFooterType = 'list' | 'detail'

const ResultCard: React.FC<{
  data: ResultCardData
  footer: ResultCardFooterType
  consolidate?: boolean
  onDetailClick: Function
}> = ({ data, footer, consolidate, onDetailClick }) => {
  // TODO: connect this w/ user's profile if they are logged in
  const { locationData } = locationState()

  // active action

  const [confirmActionModalActive, showConfirmActionModal] = React.useState(false)

  // action representative

  const [repModalActive, showRepModal] = React.useState(false)
  const [activeRep, setActiveRep] = React.useState<RepCardData>()

  React.useEffect(() => {
    if (activeRep) {
      showRepModal(true)
    }
  }, [activeRep])

  // user data

  const { account, actionsTaken, favorites, updateFavorite } = authState()

  // action presentation states

  const actionIsCompleted =
    actionsTaken && actionsTaken.find(({ action_slug }) => action_slug == data.slug?.current)
  const actionIsFavorited = favorites && favorites.indexOf(data.slug?.current) > -1

  // card attributes

  const orgImage = data.organization?.logo?.asset?.url
  const { locationLoading } = locationState()
  const locationType = getLocationType(data.location)
  const isLocationSpecific = data.location?.isLocationSpecific
  const locationTags = getBeHeardLocationStringFromSanityLocation(data.location)
  const firstCategory = data.categories && data.categories[0]
  const { isOutreach, script, targets, targetsOther } = data.outreach || {}

  const representatives = targets
    ?.map((representative, i) => {
      const repOfType =
        representative &&
        locationData?.representatives &&
        locationData?.representatives[representative as keyof RepresentativeMap]
      return repOfType
    })
    .filter(repOfType => repOfType !== undefined) as RepresentativesObject[]
  const fewerRepsThanExpected =
    targets &&
    representatives &&
    representatives.length < [...targets].splice(targets.indexOf('other'), 1).length

  // account creation

  const [showAllCobenefits, setShowAllCobenefits] = React.useState(false)
  const uniqueCobenefits =
    data.nouns && getUniqueCobenefits(data.nouns.map(({ cobenefits }) => cobenefits).flat())
  const uniqueCobenefitsCapped = consolidate
    ? uniqueCobenefits && [...uniqueCobenefits].splice(0, 4)
    : uniqueCobenefits
  const cobenniesToShow = showAllCobenefits ? uniqueCobenefits : uniqueCobenefitsCapped

  const [createAccountModal, showCreateAccountModal] = React.useState(false)

  // Components
  const HeartButton = React.useCallback(
    () => (
      <SquareButton
        variant="contained"
        color="primary"
        disableElevation
        onClick={() => {
          if (!account) {
            // show create an account warning
            showCreateAccountModal(true)
          } else {
            updateFavorite(data.slug?.current, !actionIsFavorited)
          }
        }}
      >
        {actionIsFavorited ? <HeartIconFilled /> : <HeartIcon />}
      </SquareButton>
    ),
    [account, actionIsFavorited]
  )

  return (
    <ScrollCard elevation={0}>
      {() => ({
        content: (
          <React.Fragment>
            <VBox gap={2} alignItems="flex-start">
              <HBox gap={1} width="100%" alignItems="flex-start">
                <HBox flex={1} flexWrap="wrap" gap={1}>
                  {locationTags ? (
                    locationTags.map(tag => (
                      <LocationBox type={locationType}>
                        <T3 color="textPrimary">{tag}</T3>
                      </LocationBox>
                    ))
                  ) : (
                    <LocationBox>
                      <T3 color="textPrimary">Global</T3>
                    </LocationBox>
                  )}
                </HBox>

                <HBox gap={0} flex={0} sx={{ mt: theme => theme.spacing(-0.5) }}>
                  {data.url && (
                    <Tooltip
                      placement="right"
                      title={
                        <TooltipTitle description={'Navigate to ' + extractSimpleUrl(data.url)} />
                      }
                    >
                      <IconButton
                        color="secondary"
                        onClick={() => {
                          data.url && window.open(data.url, '_blank')
                          if (!actionIsCompleted && !isOutreach) {
                            showConfirmActionModal(true)
                          }
                        }}
                      >
                        <OpenInNew />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Box sx={{ mt: theme => theme.spacing(-0.25) }}>
                    <ShareButtonIcon text={data.shareUrl} />
                  </Box>
                </HBox>
              </HBox>
              <Lockup gap={1}>
                {orgImage && (
                  <Box>
                    <img
                      style={{
                        maxWidth: 200,
                        maxHeight: 100,
                        objectFit: 'contain'
                      }}
                      src={orgImage}
                    />
                  </Box>
                )}
                <HBox mb={1.5} gap={1} alignItems="center">
                  <Typography color="textSecondary" variant="h3">
                    {data.title}
                  </Typography>
                </HBox>
              </Lockup>
            </VBox>
            <VBox flex={1} justifyContent="center" gap={1}>
              <Box>
                {blocksToText(data.description).map(block => (
                  <Box mb={1.2}>
                    <T3 color="textSecondary">{block}</T3>
                  </Box>
                ))}
                {data.rank && (
                  <HBox gap={1}>
                    <T3 variant="body2" color="textSecondary">
                      Impact Score:
                    </T3>
                    <HBox alignItems="center" gap={0.25}>
                      {createEmptyArray(data.rank).map(_ => (
                        <GlobeIcon />
                      ))}
                      {createEmptyArray(3 - data.rank).map(_ => (
                        <Box sx={{ opacity: 0.25 }}>
                          <GlobeIcon />
                        </Box>
                      ))}
                    </HBox>
                  </HBox>
                )}
              </Box>

              {!locationLoading && targets && targets.length > 0 && (
                <VBox my={2} gap={1}>
                  {representatives && representatives.length > 0 && (
                    <Box>
                      {locationLoading ? (
                        <CircularProgress color="secondary" />
                      ) : (
                        <Box>
                          <Box mb={1}>
                            <Typography variant="body1" color="textSecondary">
                              Your Representatives:
                            </Typography>
                          </Box>
                          <VBox gap={1}>
                            {representatives.map((representative, i) => {
                              const { office, representatives } = representative
                              return (
                                <VBox gap={1}>
                                  {representatives?.map(rep => {
                                    return (
                                      <RepresentativeButton
                                        name={`${rep.name}`}
                                        title={`${rep.party}, ${office}`}
                                        photo={rep.photoUrl}
                                        onClick={() => {
                                          setActiveRep({
                                            name: rep.name,
                                            party: rep.party,
                                            photo: rep.photoUrl,
                                            websites: rep.urls as string[],
                                            title: office,
                                            phones: rep.phones,
                                            emails: rep.emails
                                          })
                                        }}
                                      />
                                    )
                                  })}
                                </VBox>
                              )
                            })}
                          </VBox>
                        </Box>
                      )}
                    </Box>
                  )}

                  {fewerRepsThanExpected && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        p: 1,
                        borderRadius: '9px',
                        background: theme => theme.palette.error.light
                      }}
                    >
                      <WarningOutlined />
                      <T3 color="textPrimary">
                        We didn't find all the representatives for your area that we were hoping
                        for. This usually happens if your address isn't complete. Consider filling
                        out your address again so we can find all the people we want you to reach
                        out to!
                      </T3>
                    </Box>
                  )}
                  {targetsOther && targetsOther.length > 0 && (
                    <Box>
                      <Box my={1}>
                        <Typography variant="body1" color="textSecondary">
                          Other folks you can contact:
                        </Typography>
                      </Box>
                      <VBox gap={1}>
                        {targetsOther.map((otherTarget, i) => {
                          const { name, title, photoUrl } = otherTarget

                          return (
                            <RepresentativeButton
                              name={name}
                              title={title}
                              photo={photoUrl}
                              onClick={() => {
                                setActiveRep(otherTarget)
                              }}
                            />
                          )
                        })}
                      </VBox>
                    </Box>
                  )}
                </VBox>
              )}

              {cobenniesToShow && cobenniesToShow.length > 0 && (
                <InfoBox sx={{ my: 2 }}>
                  <Typography color="textSecondary" variant="h6">
                    Co-Benefits
                    <Tooltip
                      enterTouchDelay={0}
                      placement="top"
                      title={
                        <TooltipTitle
                          description={[
                            `Co-benefits are the intended positive side effects of a policy`
                          ]}
                        />
                      }
                    >
                      <InfoOutlined
                        fontSize="small"
                        sx={{ transform: `translateY(-0.25em)`, color: 'currentcolor' }}
                      />
                    </Tooltip>
                  </Typography>

                  <HBox flexWrap="wrap" gap={0.5}>
                    {cobenniesToShow.map(tag => {
                      return (
                        <Tag>
                          <T3 color="textSecondary">{tag?.title}</T3>
                        </Tag>
                      )
                    })}
                    {uniqueCobenefits.length > cobenniesToShow.length && (
                      <Button sx={{ p: 0 }} onClick={() => onDetailClick && onDetailClick()}>
                        <Tag>
                          <T3 color="textSecondary">...</T3>
                        </Tag>
                      </Button>
                    )}
                  </HBox>
                </InfoBox>
              )}
            </VBox>

            <FullScreenModal
              open={Boolean(repModalActive)}
              onClose={() => {
                showRepModal(false)
                showConfirmActionModal(true)
              }}
            >
              {activeRep && (
                <RepCard
                  {...{
                    ...activeRep,
                    script
                  }}
                />
              )}
            </FullScreenModal>
            <CenteredModal
              open={confirmActionModalActive}
              onClose={() => showConfirmActionModal(false)}
            >
              <ConfirmActionCard
                action={data}
                isCandidate={isOutreach}
                onDeny={() => showConfirmActionModal(false)}
              />
            </CenteredModal>

            <CenteredModal open={createAccountModal} onClose={() => showCreateAccountModal(false)}>
              <SignupWarningCard
                description="You need to be logged in to favorite actions.
"
              />
            </CenteredModal>
          </React.Fragment>
        ),
        footer: (
          <Box>
            {footer === 'list' ? (
              <HBox gap={4} alignItems="flex-end">
                <HBox flexWrap="wrap" gap={0.5} flex={1}>
                  {data.tags?.map(tag => {
                    return (
                      <Tag>
                        <T3 color="textSecondary">{tag?.title}</T3>
                      </Tag>
                    )
                  })}
                </HBox>

                <HBox gap={0.5}>
                  <HeartButton />
                  <SquareButton
                    variant="contained"
                    color={actionIsCompleted ? 'success' : 'primary'}
                    disableElevation
                    onClick={() => {
                      onDetailClick && onDetailClick()
                    }}
                  >
                    {actionIsCompleted ? (
                      <Check fontSize="large" />
                    ) : (
                      <ArrowRight color={colors.WHITE} />
                    )}
                  </SquareButton>
                </HBox>
              </HBox>
            ) : (
              <HBox gap={1} width={'100%'}>
                <Button
                  variant="contained"
                  disableElevation
                  color={actionIsCompleted ? 'success' : 'primary'}
                  startIcon={
                    actionIsCompleted ? (
                      <Check fontSize="large" />
                    ) : (
                      iconsByCategory[firstCategory?.slug?.current]
                    )
                  }
                  sx={{ flex: 1 }}
                  onClick={() => {
                    data.url && window.open(data.url, '_blank')
                    if (!actionIsCompleted && !isOutreach) {
                      showConfirmActionModal(true)
                    }
                  }}
                >
                  {ctaLabelsByCategory[firstCategory?.slug?.current] || firstCategory?.title}
                </Button>
                <HeartButton />
              </HBox>
            )}
          </Box>
        )
      })}
    </ScrollCard>
  )
}

const locationTagColorMap = {
  federal: colors.ALZARIAN_100,
  state: colors.GEORGIAN_BAY_70,
  city: colors.GREEN_100,
  local: colors.GREEN_100
}

export const LocationBox = styled(PillSmall)<{ type?: string }>`
  ${({ theme, type }) => `
    background: ${
      type
        ? locationTagColorMap[type]
        : theme.palette.mode == 'dark'
        ? colors.DARK_MODE_50
        : colors.GEORGIAN_BAY_70
    };
  `}
`

const Tag = styled(BlueBox)``

const Lockup = styled(VBox)`
  ${({ theme }) => `
    ${theme.breakpoints.down('md')} {
      flex-direction: row;
      
      img {
        width: 64px;
        height: 64px;
        object-fit: contain;
      }
    }
  `}
`

export const InfoBox = styled(Box)`
  ${({ theme }) => `
    display: flex;
    padding: ${theme.spacing(2)} ${theme.spacing(2)};
    flex-direction: column;
    align-items: flex-start;
    gap: ${theme.spacing(1.25)};
    align-self: stretch;
    border-radius: ${theme.shape.borderRadius};
    background: ${theme.palette.mode == 'dark' ? colors.DARK_MODE_70 : colors.BLACK_10};
`}
`
export default ResultCard
