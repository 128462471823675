import { Box, Card, CardTypeMap, styled } from '@mui/material'
import { colors } from '../constants/colors'
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { VBox } from './basic-elements'
import { isInBrowser } from '../utils/browser'

export const ScrollCardEl = styled(Card)`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${({ theme }) => `
    background: ${theme.palette.mode == 'dark' ? colors.DARK_MODE_80 : colors.WHITE};
  `}
`

export const ScrollCardContent = styled(VBox)`
  flex: 1;
  padding: ${({ theme }) => theme.spacing(2)};
  padding-bottom: 0;
  gap: ${({ theme }) => theme.spacing(1)};
  justify-content: space-between;
  overflow: scroll;
`

export const ScrollCardFooter = styled(Box)<{ divider: boolean }>`
  ${({ theme, divider }) => `
    position: relative;
    flex: 0;
    padding: ${theme.spacing(2)};

    &:after {
      content: '';
      width: 100%;
      height: 30px;
      content: '';
      width: 100%;
      height: 30px;
      position: absolute;
      bottom: 100%;
      left: 0;
      background: linear-gradient(180deg, ${
        theme.palette.mode == 'dark' ? 'rgb(0 57  78 / 0%)' : 'rgb(111 177 200 / 0%)'
      } 0%, ${
    theme.palette.mode == 'dark' ? 'rgb(0 57  78 / 60%)' : 'rgb(111 177 200 / 15%)'
  } 100%);
      opacity: ${divider ? 1 : 0};
      transition: opacity 0.1s linear;
    }
  `}
`

export const ScrollCard = (
  props: CardTypeMap & {
    children: () => { content: ReactNode; footer: ReactNode }
  }
) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const [atBottom, isAtBottom] = useState(false)
  const scrollHandler = useCallback(() => {
    const ref = scrollRef.current
    if (ref) {
      // not sure why we need to add the +1, this could be a potential issue
      const scrolledTo = ref.scrollTop + ref.offsetHeight + 1
      isAtBottom(ref.scrollHeight <= scrolledTo + 1)
    }
  }, [scrollRef.current])
  useEffect(() => {
    const ref = scrollRef.current
    if (isInBrowser() && ref) {
      ref.addEventListener('scroll', scrollHandler)
      scrollHandler()
      return () => {
        ref.removeEventListener('scroll', scrollHandler)
      }
    }
  }, [scrollRef])

  const { children } = props

  return (
    <ScrollCardEl {...props}>
      <ScrollCardContent ref={scrollRef}>{children().content}</ScrollCardContent>
      {children().footer && (
        <ScrollCardFooter divider={!atBottom}>{children().footer}</ScrollCardFooter>
      )}
    </ScrollCardEl>
  )
}
