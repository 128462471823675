import { Box, Button, Card, Typography, styled } from '@mui/material'
import { navigate } from 'gatsby'
import * as React from 'react'
import { colors } from '../constants/colors'
import { VBox } from '../elements/basic-elements'
import HighFive from '../images/icons/illustrations/high-five'
import OutreachIcon from '../images/icons/outreach-icon'

const SignupWarningCard = ({
  headline,
  description
}: {
  headline?: string
  description?: string
}) => {
  return (
    <CardEl>
      <CardContent>
        <HighFive />
        <Typography variant="h4" color="textPrimary" pt={2}>
          {headline || 'Hold on there partner!'}
        </Typography>
        <Typography variant="body2">
          {description || 'You need to be logged in before you can do this.'}
          <span> </span> Already got a profile? Slap the big button below.
        </Typography>
        <VBox gap={1} width="100%" pt={2}>
          <Button
            fullWidth
            variant="contained"
            disableElevation
            sx={{ py: 2 }}
            color="secondary"
            startIcon={<OutreachIcon />}
            onClick={() => navigate('/access-account')}
          >
            <Typography variant="body1" color="textPrimary" fontSize={24} color="textPrimary">
              Login to Profile
            </Typography>
          </Button>
          <Button
            fullWidth
            variant="text"
            disableElevation
            color="secondary"
            onClick={() => navigate('/register')}
          >
            <Typography variant="body2" color="textPrimary" fontSize={16} color="textPrimary">
              I need to make a free profile!
            </Typography>
          </Button>
        </VBox>
      </CardContent>
    </CardEl>
  )
}

const CardEl = styled(Card)`
  ${({ theme }) => `
    padding: ${theme.spacing(2.5)};
    width: 450px;
    max-width: 100%;
    overflow: scroll;
    margin:auto;
    &::-webkit-scrollbar {
      display: none;
    }
    background: ${theme.palette.mode == 'dark' ? colors.DARK_MODE_80 : colors.GEORGIAN_BAY_70};
  `}
`

const CardContent = styled(Box)`
  flex: 1;
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(1)};
  text-align: center;
`
export default SignupWarningCard
