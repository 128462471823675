import { User } from 'firebase/auth'
import { redirect } from './routing'
import { ActionToolAccountWithId } from '../types/firebase-types'
import { attempt } from '../async/utils'
import {
  accountExists,
  handleFirebaseRedirectUrl,
  isFirebaseRedirectUrl
} from '../async/firebase/utils'
import { initializeAccount } from './account'
import { getDomain } from './browser'

export const getEmailFrom = (user?: User) => {
  return user?.email
}
export const getUidFrom = (user?: User) => {
  return user?.uid
}

export const attemptLogin = async (email: string, redirectUrl: string) => {
  const alreadyExists = await accountExists(email)

  if (!alreadyExists) {
    redirect('/register/notfound')
  } else {
    await attempt(async () => {
      await sendEmailVerification(email, redirectUrl)
      redirect('/verify-email')
    })
  }
}

export const sendEmailVerification = async (email: string, redirectUrl = '') => {
  await attempt(async () => {
    const res = await fetch(
      `/.netlify/functions/email-login-link?email=${email}&redirectUrl=${getDomain()}${redirectUrl}`
    )
    const body = await res?.json()

    if (!res.ok) {
      throw new Error(body)
    }
    window.localStorage.setItem('wcid_signin_email', email)
  })
}

export const handleAuthUser = async (
  authUser?: User,
  setAccount?: (user: ActionToolAccountWithId) => void
) => {
  // whenever account's auth status change, if they're authenticated,
  // get their profile info, beehiiv authUser and stripe authUser
  const email = getEmailFrom(authUser)
  const uid = getUidFrom(authUser)
  if (email && uid) {
    // we have valid auth info from firebase client
    await initializeAccount(uid, email, setAccount)
  } else if (isFirebaseRedirectUrl()) {
    // if user is coming from a verification email, try and authenticate them
    try {
      await handleFirebaseRedirectUrl()
    } catch (err) {
      await redirect('/access-account')
    }
  }
}
