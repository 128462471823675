import React from 'react'

const DonateIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="27"
    viewBox="0 0 25 27"
    fill="none"
  >
    <path
      d="M19.1438 10.7556C19.9146 9.98482 19.9146 8.74427 19.9146 6.26318C19.9146 3.7821 19.9146 2.54155 19.1438 1.77078M19.1438 10.7556C18.373 11.5264 17.1325 11.5264 14.6514 11.5264H9.38819C6.9071 11.5264 5.66655 11.5264 4.89578 10.7556M19.1438 10.7556C19.1438 10.7556 19.1438 10.7556 19.1438 10.7556ZM19.1438 1.77078C18.373 1 17.1325 1 14.6514 1L9.38818 1C6.9071 1 5.66655 1 4.89578 1.77078M19.1438 1.77078C19.1438 1.77078 19.1438 1.77078 19.1438 1.77078ZM4.89578 1.77078C4.125 2.54155 4.125 3.7821 4.125 6.26319C4.125 8.74427 4.125 9.98482 4.89578 10.7556M4.89578 1.77078C4.89578 1.77078 4.89578 1.77078 4.89578 1.77078ZM4.89578 10.7556C4.89578 10.7556 4.89578 10.7556 4.89578 10.7556Z"
      stroke="white"
      stroke-width="1.6"
    />
    <path
      d="M13.3347 6.26111C13.3347 6.9878 12.7456 7.57691 12.0189 7.57691C11.2922 7.57691 10.7031 6.9878 10.7031 6.26111C10.7031 5.53441 11.2922 4.94531 12.0189 4.94531C12.7456 4.94531 13.3347 5.53441 13.3347 6.26111Z"
      stroke="white"
      stroke-width="1.6"
    />
    <path
      d="M19.9161 4.94739C17.7361 4.94739 15.9688 3.18008 15.9688 1"
      stroke="white"
      stroke-width="1.6"
      stroke-linecap="round"
    />
    <path
      d="M19.9161 7.57812C17.7361 7.57812 15.9688 9.34543 15.9688 11.5255"
      stroke="white"
      stroke-width="1.6"
      stroke-linecap="round"
    />
    <path
      d="M4.125 4.94739C6.30508 4.94739 8.07239 3.18008 8.07239 1"
      stroke="white"
      stroke-width="1.6"
      stroke-linecap="round"
    />
    <path
      d="M4.125 7.57812C6.30508 7.57812 8.07239 9.34543 8.07239 11.5255"
      stroke="white"
      stroke-width="1.6"
      stroke-linecap="round"
    />
    <path
      d="M1.5 25.1949H4.47361C5.8037 25.1949 7.1481 25.3334 8.44257 25.6001C10.7324 26.0719 13.1433 26.129 15.4566 25.7546C16.5972 25.5699 17.7186 25.2876 18.7336 24.7977C19.6499 24.3554 20.7724 23.732 21.5263 23.0337C22.2792 22.3364 23.0632 21.1952 23.6198 20.3032C24.097 19.5383 23.8662 18.5998 23.1113 18.0299C22.2729 17.3968 21.0287 17.3969 20.1904 18.0302L17.8125 19.8265C16.8909 20.5227 15.8842 21.1635 14.685 21.3548C14.5409 21.3778 14.3898 21.3987 14.2321 21.417M14.2321 21.417C14.1847 21.4225 14.1366 21.4277 14.088 21.4327M14.2321 21.417C14.424 21.3759 14.6145 21.2576 14.7932 21.1016C15.6395 20.3629 15.693 19.118 14.9587 18.2932C14.7883 18.1018 14.5889 17.9422 14.3673 17.81C10.6865 15.6146 4.95978 17.2867 1.5 19.7403M14.2321 21.417C14.1841 21.4273 14.136 21.4327 14.088 21.4327M14.088 21.4327C13.3993 21.5034 12.5937 21.5217 11.6997 21.4373"
      stroke="white"
      stroke-width="1.6"
      stroke-linecap="round"
    />
  </svg>
)

export default DonateIcon
