import * as React from 'react'
import { PropsWithChildren, useContext } from 'react'
import { actionDataState } from './action-data-context'
import { CandidateCategories, candidateNounSlugs } from '../utils/sanity'

export const ActionSelectorContext = React.createContext<{
  loading?: boolean
  error?: string
  includeCandidates?: boolean

  optionsVerbs?: Queries.SanityVerb[]
  optionsNouns?: Queries.SanityNoun[]
  optionsEndorsements?: Queries.SanityOrganization[]
  optionsIssues?: Queries.SanityNoun[]
  selectedVerb?: Queries.SanityVerb
  selectedNoun?: Queries.SanityNoun
  selectedEndorsement?: Queries.SanityOrganization
  selectedIssue?: Queries.SanityNoun
  selectedCandidateCategory?: CandidateCategories
  setSelectedVerb: Function
  setSelectedNoun: Function
  setSelectedCandidateCategory: Function
  setSelectedEndorsement: Function
  setSelectedIssue: Function
  fetchVerbs: Function
  handleVerbSelect: Function
}>({
  setSelectedVerb: () => {},
  setSelectedNoun: () => {},
  setSelectedEndorsement: () => {},
  setSelectedIssue: () => {},
  setSelectedCandidateCategory: () => {},

  fetchVerbs: () => {},
  handleVerbSelect: () => {}
})

export const ActionSelectorProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { actions, setActions } = actionDataState()
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState<string>()
  const [optionsVerbs, setOptionsVerbs] = React.useState<Queries.SanityVerb[]>()
  const [selectedVerb, setSelectedVerb] = React.useState<Queries.SanityVerb>()
  const [optionsNouns, setOptionsNouns] = React.useState<Queries.SanityNoun[]>()
  const [selectedNoun, setSelectedNoun] = React.useState<Queries.SanityNoun>()
  const [includeCandidates, setIncludeCandidates] = React.useState(false)

  const [selectedCandidateCategory, setSelectedCandidateCategory] =
    React.useState<CandidateCategories>()
  const [optionsEndorsements, setOptionsEndorsements] =
    React.useState<Queries.SanityOrganization[]>()
  const [selectedEndorsement, setSelectedEndorsement] = React.useState<Queries.SanityOrganization>()

  const [optionsIssues, setOptionsIssues] = React.useState<Queries.SanityNoun[]>()
  const [selectedIssue, setSelectedIssue] = React.useState<Queries.SanityNoun>()

  // intial load

  const fetchVerbs = async () => {
    setLoading(true)
    const response = await fetch(`/.netlify/functions/sanity-verbs`)
    if (response?.status !== 200) {
      setError('There was an error loading this data.')
    }

    const verbs = (await response?.json()) as Queries.SanityVerb[]
    setOptionsVerbs(verbs)
    setLoading(false)
  }

  const generateQueryString = (obj: { [s: string]: unknown } | ArrayLike<unknown>) => {
    const queryParams = []

    for (const [key, value] of Object.entries(obj)) {
      if (value) {
        queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value as string)}`)
      }
    }

    return queryParams.length ? `?${queryParams.join('&')}` : ''
  }

  const handleVerbSelect = async (verb: Queries.SanityVerb) => {
    setLoading(true)
    setSelectedNoun(undefined)
    setOptionsNouns([])

    setSelectedVerb(verb)
    const categorySlug = verb?.category?.slug?.current
    const slugs = verb?.category?.include_candidates ? candidateNounSlugs : null

    const response = await fetch(
      `/.netlify/functions/sanity-nouns${generateQueryString({
        category: categorySlug,
        slugs: slugs
      })}`
    )

    if (response?.status !== 200) {
      setError('There was an error loading this data.')
    }

    const nouns = (await response?.json()) as Queries.SanityNoun[]

    setOptionsNouns(nouns)
    setLoading(false)
  }

  const fetchCandidateFields = async () => {
    setLoading(true)
    const response = await fetch(`/.netlify/functions/sanity-get-candidate-searchable-data`)
    if (response?.status !== 200) {
      setError('There was an error loading this data.')
    } else {
      const { endorsements, issues } = (await response?.json()) as {
        endorsements: Queries.SanityOrganization[]
        issues: Queries.SanityNoun[]
      }
      setOptionsEndorsements(endorsements.filter(exists => exists))
      setOptionsIssues(issues.filter(exists => exists))
    }
    setLoading(false)
  }

  React.useEffect(() => {
    if (selectedCandidateCategory) {
      fetchCandidateFields()
    }
  }, [selectedCandidateCategory])

  React.useEffect(() => {
    if (!selectedVerb) {
      setSelectedNoun(undefined)
      setSelectedEndorsement(undefined)
      setSelectedCandidateCategory(undefined)
      setSelectedIssue(undefined)
    }
    if (!selectedNoun) {
      setSelectedEndorsement(undefined)
      setSelectedCandidateCategory(undefined)
      setSelectedIssue(undefined)
    }
  }, [selectedVerb, selectedNoun])

  // clear out error
  React.useEffect(() => {
    setError(undefined)
  }, [selectedVerb, selectedNoun, selectedEndorsement, selectedIssue, selectedCandidateCategory])

  // if user is coming to website on an action page, we need to infer
  // the selected verb/noun
  // React.useEffect(() => {
  //   const fetchQueryFromFirstAction = async () => {
  //     setLoading(true)

  //     const verbResponse = await fetch(
  //       `/.netlify/functions/sanity-verb?verb=${verbParam}`
  //     )
  //     if (verbResponse?.status !== 200) {
  //       setError('There was an error loading this data.')
  //     }
  //     const verb = (await verbResponse?.json()) as Queries.SanityVerb
  //     setSelectedVerb(verb)

  //     const nounResponse = await fetch(
  //       `/.netlify/functions/sanity-noun?noun=${nounParam}`
  //     )
  //     if (nounResponse?.status !== 200) {
  //       setError('There was an error loading this data.')
  //     }
  //     const noun = (await nounResponse?.json()) as Queries.SanityNoun
  //     setSelectedNoun(noun)

  //     await fetchActionsFromActionInputs()
  //   }
  //   if (nounParam && verbParam) {
  //     fetchQueryFromFirstAction()
  //   }
  // }, [])

  return (
    <ActionSelectorContext.Provider
      value={{
        loading,
        error,
        includeCandidates,
        optionsVerbs,
        optionsNouns,
        optionsEndorsements,
        optionsIssues,
        selectedVerb,
        selectedNoun,
        selectedEndorsement,
        selectedIssue,
        selectedCandidateCategory,
        setSelectedVerb,
        setSelectedNoun,
        setSelectedCandidateCategory,
        setSelectedEndorsement,
        setSelectedIssue,
        fetchVerbs,
        handleVerbSelect
      }}
    >
      {children}
    </ActionSelectorContext.Provider>
  )
}

export const actionSelectorState = () => useContext(ActionSelectorContext)
