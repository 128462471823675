import {styled} from '@mui/material'
import React from 'react'
import {colors} from '../../constants/colors'

const CloseIcon = ({color, size = 14}: {color?: string; size?: number}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox={`0 0 14 14`}
    fill="none"
  >
    <StyledPath
      d="M13 1L1 13M1 1L13 13"
      strokeColor={color}
      strokeWidth="2px"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const StyledPath = styled('path')<{strokeColor?: string}>`
  ${({theme, strokeColor}) => `
    stroke: ${
      strokeColor || theme.palette.mode == 'dark'
        ? colors.DARK_MODE_10
        : colors.BLACK_100
    };
  `}
`

export default CloseIcon
