import React from 'react'
import { styled, useTheme } from '@mui/material'

import iniLogo from '../images/logos/ini-logo-inline.svg'
import iniLogoDark from '../images/logos/ini-logo-inline-dark.svg'
import iniLogoMid from '../images/logos/ini-logo-inline-mid.svg'

export const LogoHorz = ({
  size,
  objectPosition,
  invertColor
}: {
  size: number
  objectPosition?: string
  invertColor?: boolean
}) => {
  const theme = useTheme()
  const isDark = theme.palette.mode == 'dark'
  return (
    <StyledImage
      src={isDark ? iniLogoDark : invertColor ? iniLogoMid : iniLogo}
      height={size}
      width="auto"
      objectPosition={objectPosition}
    />
  )
}

const StyledImage = styled('img')<{ objectPosition?: string }>`
  object-fit: contain;
  object-position: ${({ objectPosition }) => objectPosition || 'center'};
`
