import { Box, styled, Typography, useTheme } from '@mui/material'
import { graphql, Link, useStaticQuery } from 'gatsby'
import React from 'react'
import { HBox, VBox } from '../elements/basic-elements'
const Sponsors = () => {
  const data = useStaticQuery<Queries.HeaderQueryQuery>(graphql`
    query HeaderQuery {
      allSanitySiteSettings {
        nodes {
          sponsors {
            title
            url
            logo {
              asset {
                url
              }
            }
            dark_logo {
              asset {
                url
              }
            }
          }
        }
      }
    }
  `)
  const sponsors = data.allSanitySiteSettings.nodes
    .map(({ sponsors }) => sponsors)
    .flat() as Queries.SanitySponsor[]

  const theme = useTheme()
  const isDark = theme.palette.mode == 'dark'

  return (
    <SponsorBox gap={2}>
      {sponsors.length > 0 && (
        <Typography variant="caption" fontWeight="700" lineHeight="0.85" color="textSecondary">
          Sponsored by:
        </Typography>
      )}
      <HBox>
        {sponsors.map(({ logo, dark_logo, title, url }) => (
          <SponsorImage to={String(url)} target="_blank">
            <img
              src={((isDark && dark_logo) || logo)?.asset?.url || ''}
              alt={`Sponsor Logo for ${title}`}
            />
          </SponsorImage>
        ))}
      </HBox>
    </SponsorBox>
  )
}

const SponsorBox = styled(Box)`
  ${({ theme }) => `
    display: flex;
    gap: ${theme.spacing(2)};
    align-items: flex-end;
    ${theme.breakpoints.up('md')} {
      flex-direction: column;
      align-items: flex-start;
    }
  `}
`

const SponsorImage = styled(Link)`
  img {
    display: block;
    max-width: 100px;
    max-height: 32px;
  }
`
export default Sponsors
