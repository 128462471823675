import React from 'react'

const Binoculars = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="101"
    height="60"
    viewBox="0 0 101 60"
    fill="none"
  >
    <path
      d="M1.45312 51.0656C1.45312 51.0656 5.39802 17.7255 9.45977 12.2528C13.5215 6.78015 16.1226 3.38058 22.4587 3.72248C22.4587 3.72248 26.2954 -0.194283 31.2011 1.36809C33.8606 2.2142 34.0142 3.01053 34.0142 3.01053C34.0142 3.01053 37.7297 1.64291 40.9605 3.22693C44.1913 4.81095 45.9203 7.66305 45.3663 11.0951C44.8124 14.5271 35.0897 17.4809 38.5044 26.0264C41.9191 34.5719 43.8862 32.5724 44.3471 35.9546C44.808 39.3369 40.4801 40.856 37.2969 42.3535C34.1137 43.8509 29.9914 47.6746 29.9914 47.6746L29.4461 56.9537C29.4461 56.9537 25.1874 59.003 15.1402 57.8063C5.0929 56.6096 1.45312 51.0656 1.45312 51.0656Z"
      fill="#6FB1C8"
      stroke="#222222"
      strokeWidth="0.216396"
      strokeMiterlimit="10"
    />
    <path
      d="M100.495 51.0656C100.495 51.0656 96.5504 17.7255 92.5038 12.2528C88.4572 6.78015 85.8409 3.38058 79.507 3.72248C79.507 3.72248 75.6682 -0.194283 70.7625 1.36809C68.103 2.2142 67.9385 3.01053 67.9385 3.01053C67.9385 3.01053 64.223 1.64291 60.9922 3.22693C57.7614 4.81095 56.0324 7.66305 56.5864 11.0951C57.1404 14.5271 66.863 17.4809 63.4505 26.0264C60.0379 34.5719 58.0665 32.5724 57.6078 35.9546C57.149 39.3369 61.4748 40.856 64.658 42.3535C67.8411 43.8509 71.9462 47.6811 71.9462 47.6811L72.4915 56.9602C72.4915 56.9602 76.7523 59.0095 86.7974 57.8128C96.8425 56.6161 100.495 51.0656 100.495 51.0656Z"
      fill="#6FB1C8"
      stroke="#222222"
      strokeWidth="0.216396"
      strokeMiterlimit="10"
    />
    <path
      d="M50.5924 29.5938H54.6953V16.716H50.5924V29.5938Z"
      fill="#222222"
      stroke="#222222"
      strokeWidth="1.73117"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M57.4912 35.6406C56.7749 36.6793 56.9502 38.1487 57.6383 39.2047C58.3265 40.2607 59.4409 40.9683 60.5792 41.5093C62.7994 42.5631 65.2598 43.1149 67.3394 44.422C69.1117 45.5364 70.5269 47.1529 72.3165 48.2587C76.1597 50.652 81.0351 50.3339 85.5469 49.9357"
      stroke="#222222"
      strokeWidth="1.73117"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M73.3906 6.60222C75.0915 4.9706 77.3572 3.88862 79.7137 3.78908C83.7452 3.61812 87.4174 6.27114 89.8692 9.47813C92.6152 13.0725 94.2339 17.4155 95.2401 21.8257C96.2463 26.2358 96.6683 30.7563 97.2569 35.2422C97.9667 40.668 98.9174 46.0555 100.109 51.4048"
      stroke="#222222"
      strokeWidth="1.73117"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M79.3407 3.59207C76.9624 2.09666 74.098 1.57873 71.3466 2.14659C68.5953 2.71445 66.17 4.32411 64.5781 6.63893"
      stroke="#222222"
      strokeWidth="1.73117"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M67.4483 3.56781C63.8324 2.41659 59.565 3.74526 57.2409 6.7445C56.0919 8.22898 55.4124 10.3064 56.2434 11.9899"
      stroke="#222222"
      strokeWidth="1.73117"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M71.4688 48.2422C71.9283 51.3133 72.1108 54.4196 72.0141 57.5234"
      stroke="#222222"
      strokeWidth="1.73117"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M100.007 51.6406C99.1113 53.6639 97.2698 54.8368 95.2465 55.7521C88.0803 58.9569 80.0468 59.6669 72.4297 57.7689"
      stroke="#222222"
      strokeWidth="1.73117"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M68.3518 39.1957C77.21 39.1957 84.391 32.0147 84.391 23.1564C84.391 14.2982 77.21 7.11719 68.3518 7.11719C59.4935 7.11719 52.3125 14.2982 52.3125 23.1564C52.3125 32.0147 59.4935 39.1957 68.3518 39.1957Z"
      fill="#FF4438"
      stroke="#222222"
      strokeWidth="1.73117"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M68.3545 35.631C75.2468 35.631 80.8341 30.0437 80.8341 23.1514C80.8341 16.2592 75.2468 10.6719 68.3545 10.6719C61.4623 10.6719 55.875 16.2592 55.875 23.1514C55.875 30.0437 61.4623 35.631 68.3545 35.631Z"
      fill="white"
      stroke="#222222"
      strokeWidth="1.73117"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M68.3107 26.0549C69.3408 26.0549 70.176 24.6453 70.176 22.9064C70.176 21.1675 69.3408 19.7578 68.3107 19.7578C67.2805 19.7578 66.4453 21.1675 66.4453 22.9064C66.4453 24.6453 67.2805 26.0549 68.3107 26.0549Z"
      fill="#222222"
      stroke="#222222"
      strokeWidth="1.73117"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M80.8044 8.30469L77.2188 13.4982"
      stroke="#222222"
      strokeWidth="1.73117"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M70.271 5.04688L69.5547 10.3897"
      stroke="#222222"
      strokeWidth="1.73117"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M59.3828 7.03906C60.2602 8.71715 61.0061 10.4608 61.6139 12.2542"
      stroke="#222222"
      strokeWidth="1.73117"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M56.7858 17.178L52.6094 13.6875"
      stroke="#222222"
      strokeWidth="1.73117"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M85.1615 12.2969C84.6659 13.394 83.0841 14.3483 81.8831 14.2769"
      stroke="#222222"
      strokeWidth="1.73117"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M83.2232 29.1641C83.2232 29.1641 80.41 38.9019 89.7973 42.9225"
      stroke="#222222"
      strokeWidth="1.73117"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M50.7122 16.7188H46.6094V29.5965H50.7122V16.7188Z"
      fill="#222222"
      stroke="#222222"
      strokeWidth="1.73117"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M43.8183 35.6406C44.5346 36.6793 44.3593 38.1487 43.6712 39.2047C42.9831 40.2607 41.8686 40.9683 40.7304 41.5093C38.5102 42.5631 36.0497 43.1149 33.9702 44.422C32.1979 45.5364 30.7805 47.1529 29.0039 48.2587C25.1607 50.652 20.2853 50.3339 15.7734 49.9357"
      stroke="#222222"
      strokeWidth="1.73117"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.9609 3.59225C24.3396 2.09672 27.2043 1.57872 29.956 2.14657C32.7077 2.71442 35.1333 4.32414 36.7256 6.63911"
      stroke="#222222"
      strokeWidth="1.73117"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M33.8594 3.56781C37.4753 2.41659 41.7427 3.74526 44.0668 6.7445C45.2158 8.22898 45.8953 10.3064 45.0644 11.9899"
      stroke="#222222"
      strokeWidth="1.73117"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M27.9016 6.60222C26.2007 4.9706 23.935 3.88862 21.5785 3.78908C17.547 3.61812 13.8726 6.27114 11.423 9.47813C8.67696 13.0725 7.05832 17.4155 6.05208 21.8257C5.04584 26.2358 4.6217 30.7563 4.03527 35.2422C3.32405 40.668 2.37479 46.0591 1.1875 51.4156L1.2957 51.632C2.18941 53.6553 4.03311 54.8282 6.05641 55.7436C13.2225 58.9483 21.256 59.6583 28.8732 57.7604L29.306 57.544C29.21 54.4401 29.3932 51.3338 29.8535 48.2627"
      stroke="#222222"
      strokeWidth="1.73117"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32.9533 39.1957C41.8116 39.1957 48.9926 32.0147 48.9926 23.1564C48.9926 14.2982 41.8116 7.11719 32.9533 7.11719C24.0951 7.11719 16.9141 14.2982 16.9141 23.1564C16.9141 32.0147 24.0951 39.1957 32.9533 39.1957Z"
      fill="#FF4438"
      stroke="#222222"
      strokeWidth="1.73117"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32.9483 35.631C39.8406 35.631 45.4278 30.0437 45.4278 23.1514C45.4278 16.2592 39.8406 10.6719 32.9483 10.6719C26.056 10.6719 20.4688 16.2592 20.4688 23.1514C20.4688 30.0437 26.056 35.631 32.9483 35.631Z"
      fill="white"
      stroke="#222222"
      strokeWidth="1.73117"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32.9903 26.0549C34.0205 26.0549 34.8557 24.6453 34.8557 22.9064C34.8557 21.1675 34.0205 19.7578 32.9903 19.7578C31.9601 19.7578 31.125 21.1675 31.125 22.9064C31.125 24.6453 31.9601 26.0549 32.9903 26.0549Z"
      fill="#222222"
      stroke="#222222"
      strokeWidth="1.73117"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.4922 8.30469L24.0779 13.4982"
      stroke="#222222"
      strokeWidth="1.73117"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M31.0312 5.04688L31.7475 10.3897"
      stroke="#222222"
      strokeWidth="1.73117"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M41.9185 7.03906C41.0412 8.71715 40.2952 10.4608 39.6875 12.2542"
      stroke="#222222"
      strokeWidth="1.73117"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M44.5156 17.178L48.6921 13.6875"
      stroke="#222222"
      strokeWidth="1.73117"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.1406 12.2969C16.6362 13.394 18.218 14.3483 19.419 14.2769"
      stroke="#222222"
      strokeWidth="1.73117"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.0606 29.1641C18.0606 29.1641 20.8738 38.9019 11.4844 42.9225"
      stroke="#222222"
      strokeWidth="1.73117"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M85.6967 49.8276C85.6967 49.8276 84.8117 55.6357 71.9902 53.74L71.75 48.4297C71.75 48.4297 79.7242 51.5328 85.6967 49.8276Z"
      fill="#222222"
      stroke="#222222"
      strokeWidth="0.216396"
      strokeMiterlimit="10"
    />
    <path
      d="M15.6094 49.8276C15.6094 49.8276 16.4944 55.6357 29.3159 53.74L29.5561 48.4297C29.5561 48.4297 21.5819 51.5328 15.6094 49.8276Z"
      fill="#222222"
      stroke="#222222"
      strokeWidth="0.216396"
      strokeMiterlimit="10"
    />
  </svg>
)

export default Binoculars
