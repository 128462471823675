import { Box } from '@mui/system'
import React, { FC, PropsWithChildren, useEffect } from 'react'
import { colors } from '../../constants/colors'
import Header from '../header'
import { styled } from '@mui/material'
import { sitePaddingHorz } from '../../constants'
import { ColorScheme, pageState } from '../../context/page-context'
import { COLLAPSE_BUTTON_SPACING_OFFSET } from '../../constants/sizes'
import { getDomain } from '../../utilities/browser'

const PageWithHeader: FC<PropsWithChildren> = ({ children }) => {
  const { pageRef, pageContentRef, colorScheme, isMobile } = pageState()

  return (
    <PageContainer ref={pageRef} colorScheme={colorScheme}>
      <HeaderContainer>
        <Header />
      </HeaderContainer>
      <PageContentContainer adjustWidth={!isMobile}>
        <PageContent ref={pageContentRef}>{children}</PageContent>
      </PageContentContainer>
    </PageContainer>
  )
}

const schemes = {
  [ColorScheme.ACTIONS]: {
    light: colors.GLACIER_BLUE_100,
    dark: colors.DARK_MODE_100
  },
  [ColorScheme.COMMUNITY]: {
    light: colors.BLACK_10,
    dark: colors.DARK_MODE_100
  }
}

const PageContainer = styled(Box)<{ colorScheme: ColorScheme }>`
  ${({ colorScheme, theme }) => {
    const scheme = schemes[colorScheme]
    return `
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100svh;
    background: ${scheme.light};

    @media (prefers-color-scheme: dark) {
      background: ${scheme.dark};
    }

    ${theme.breakpoints.up('md')} {
      flex-direction: row;
    }
  `
  }}

  * {
    &::-webkit-scrollbar {
      display: none;
    }
  }
`
const HeaderContainer = styled(Box)`
  overflow: auto;
  ${({ theme }) => `
    ${theme.breakpoints.up('md')} {
      display: flex;
      align-items: stretch;
      ${theme.breakpoints.up('md')} {
        padding-right: 0;
      }
    }
  `}
`

const PageContentContainer = styled(Box)<{ adjustWidth?: boolean }>`
  flex: 1;
  padding: 0;
  display: flex;
  align-items: stretch;
  overflow: hidden;
  ${({ adjustWidth, theme }) =>
    adjustWidth &&
    `
    width: calc(100% + ${theme.spacing(COLLAPSE_BUTTON_SPACING_OFFSET)});
    margin-left: -${theme.spacing(COLLAPSE_BUTTON_SPACING_OFFSET)};
  `}
`
const PageContent = styled(Box)`
  flex: 1;
  padding: 0;
  display: flex;
  align-items: stretch;
  overflow: hidden;
`

export default PageWithHeader
