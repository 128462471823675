import {
  fetchSignInMethodsForEmail,
  isSignInWithEmailLink,
  signInWithEmailLink
} from 'firebase/auth'
import { isInBrowser } from '../../utilities/browser'
import { documentId, where } from 'firebase/firestore'
import { auth } from './config'

export const accountExists = async (email: string) => {
  const signInMethods = await fetchSignInMethodsForEmail(auth, email)

  return signInMethods.length > 0
}

export const isFirebaseRedirectUrl = () => {
  return isInBrowser() && window.location.href && isSignInWithEmailLink(auth, window.location.href)
}

export const cleanupFirebaseRedirectUrl = () => {
  const href = isInBrowser() && window.location.href
  if (href && isSignInWithEmailLink(auth, href)) {
    // remove params
    const params = new URLSearchParams(window.location.search)
    if (params) {
      window.history.replaceState({}, document.title, window.location.pathname)
    }
  }
}

export const handleFirebaseRedirectUrl = async () => {
  const email =
    window.localStorage.getItem('wcid_signin_email') ||
    window.prompt('Please provide your email for confirmation')

  // The client SDK will parse the code from the link for you.
  if (email) {
    await signInWithEmailLink(auth, email, window.location.href)
      .then(() => {
        // Clear email from storage.
        window.localStorage.removeItem('wcid_signin_email')

        // remove params
        cleanupFirebaseRedirectUrl()
      })
      .catch(error => {
        const message =
          error.code === 'auth/invalid-action-code' &&
          'Your verification has expired, please try again.'
        if (message) {
          alert(message)
          return true
        } else {
          // setError(error.message)
        }
      })
  }
}

export const createDocumentIdQuery = (ids: string[]) => {
  return where(documentId(), 'in', ids)
}

export const createInArrayQuery = (field: string, ids: string[]) => {
  return where(field, 'in', ids)
}
