import {styled} from '@mui/material'
import React from 'react'
import {colors} from '../../constants/colors'

const MagGlassIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <StyledPath d="M20 21.7864L16.2323 16.0446C17.8719 14.5715 18.9152 12.4289 18.9152 10.0434C18.907 5.60741 15.338 2 10.9576 2C6.5772 2 3 5.60741 3 10.0434C3 14.4795 6.56892 18.0869 10.9576 18.0869C12.208 18.0869 13.3838 17.7856 14.4355 17.2666L18.1948 23L20 21.7947V21.7864ZM5.16123 10.0434C5.16123 6.81267 7.76132 4.18454 10.9576 4.18454C14.1539 4.18454 16.754 6.81267 16.754 10.0434C16.754 13.2742 14.1539 15.9024 10.9576 15.9024C7.76132 15.9024 5.16123 13.2742 5.16123 10.0434Z" />
  </svg>
)

const StyledPath = styled('path')`
  ${({theme}) => `
    fill: ${
      theme.palette.mode == 'dark' ? colors.DARK_MODE_10 : colors.BLACK_100
    };
  `}
`

export default MagGlassIcon
