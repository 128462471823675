import { Box, Button, Divider, Typography } from '@mui/material'
import * as React from 'react'
import { colors } from '../constants/colors'
import { HBox, VBox } from '../elements/basic-elements'

import { filtersState } from '../state/filters-state'
import { locationState } from '../context/location-context'
import { resultsState } from '../context/results-context'
import { stringExists } from '../utils'
import FormSwitch from './form-switch'
import Input from './input'

const LocationControls: React.FC<{ onSave: Function }> = ({ onSave }) => {
  const { settings, setSettings } = locationState()
  const { address, prioritizeLocation, restrictToLocation } = settings

  const [tempLocation, setTempLocation] = React.useState(address)
  const [tempPrioritizeLocation, setTempPrioritizeLocation] = React.useState(prioritizeLocation)
  const [tempRestrictLocation, setTempRestrictLocation] = React.useState(restrictToLocation)

  const clearAllFilters = () => {
    setTempLocation('')
    setTempPrioritizeLocation(false)
    setTempRestrictLocation(false)
  }

  const saveChanges = React.useCallback(async () => {
    await setSettings({
      address: tempLocation,
      prioritizeLocation: tempPrioritizeLocation,
      restrictToLocation: tempRestrictLocation
    })
    onSave()
  }, [tempLocation, tempPrioritizeLocation, tempRestrictLocation])

  return (
    <VBox flex={1} pb={2} gap={2}>
      <VBox flex={1} px={2}>
        <HBox py={3} alignItems="center">
          <HBox sx={{ flex: 1 }}>
            <Button
              variant="text"
              color="info"
              sx={{ fontSize: 15, color: colors.BLACK_50 }}
              onClick={clearAllFilters}
            >
              Clear All
            </Button>
          </HBox>
          <Typography sx={{ fontSize: 28 }} color="textSecondary">
            Location
          </Typography>
          <Box sx={{ flex: 1 }}></Box>
        </HBox>
        <VBox gap={1}>
          <Box sx={{ opacity: stringExists(tempLocation) ? 1 : 0.5 }}>
            <Input
              hasValue={tempLocation !== ''}
              onCloseClick={() => {
                setTempLocation('')
              }}
              disableClose
              label={'Your location'}
            >
              {({ TextBox }) => {
                return (
                  <TextBox
                    type="text"
                    value={tempLocation}
                    aria-label={'Location input'}
                    onChange={e => {
                      setTempLocation(e.target.value)
                    }}
                    onBlur={e => {
                      setTempLocation(e.target.value)
                    }}
                  />
                )
              }}
            </Input>
          </Box>
          <Box width="100%">
            <FormSwitch
              text="Prioritize actions near me"
              value={Boolean(tempLocation && tempPrioritizeLocation)}
              disabled={!tempLocation}
              onChange={async (shouldPrioritize: boolean) =>
                setTempPrioritizeLocation(shouldPrioritize)
              }
            />
          </Box>
          <Divider />

          <Box width="100%">
            <FormSwitch
              text="Only show results near me"
              value={Boolean(tempLocation && tempRestrictLocation)}
              disabled={!tempLocation || !tempPrioritizeLocation}
              onChange={async (shouldRestrict: boolean) => setTempRestrictLocation(shouldRestrict)}
            />
          </Box>
        </VBox>
      </VBox>
      <Box px={2}>
        <Button
          fullWidth
          variant="contained"
          disableElevation
          color="primary"
          onClick={saveChanges}
        >
          show actions
        </Button>
      </Box>
    </VBox>
  )
}

export default LocationControls
