import { Modal, Slide, styled, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import React, { PropsWithChildren, useLayoutEffect, useState } from 'react'
import { colors } from '../constants/colors'
import { SquareButton, VBox } from '../elements/basic-elements'
import CloseIcon from '../images/icons/close-icon'
import { isInBrowser } from '../utils/browser'

type ColorSchemes = 'white' | 'grey'

const FullScreenModal: React.FC<
  PropsWithChildren & {
    open: boolean
    onClose: () => void
    contentPadding?: boolean
    colorScheme?: ColorSchemes
  }
> = ({
  children,
  open,
  allowInnerScroll = false,
  contentPadding = true,
  colorScheme = 'white',
  onClose
}) => {
  const [slideDirection, setSlideDirection] = useState<'up' | 'left'>('up')
  const theme = useTheme()

  useLayoutEffect(() => {
    if (isInBrowser()) {
      const setDirection = () => {
        const { innerWidth } = window
        setSlideDirection(innerWidth > theme.breakpoints.values.md ? 'left' : 'up')
      }
      setDirection()
      window.addEventListener('resize', setDirection)
      return () => window.removeEventListener('resize', setDirection)
    }
  }, [])
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      disableAutoFocus={true}
      sx={{
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: theme => theme.zIndex.modal
      }}
    >
      <Slide direction={slideDirection} in={open} timeout={500} easing="cubic-bezier(0, 0, 0, 1)">
        <ModalContainer
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            overflow: 'scroll'
          }}
          colorScheme={colorScheme}
        >
          <Box
            position="absolute"
            zIndex={10}
            right={theme => theme.spacing(1)}
            top={theme => theme.spacing(1)}
          >
            <SquareButton
              variant="contained"
              disableElevation
              color={
                colorScheme === 'white'
                  ? theme.palette.mode == 'dark'
                    ? 'secondary'
                    : 'info'
                  : colorScheme === 'grey'
                  ? 'tertiary'
                  : 'info'
              }
              onClick={() => {
                onClose && onClose()
              }}
            >
              <CloseIcon />
            </SquareButton>
          </Box>
          <VBox
            sx={{ pt: theme => (contentPadding ? theme.spacing(10) : 0) }}
            height={allowInnerScroll && '100%'}
            minHeight="100%"
          >
            {children}
          </VBox>
        </ModalContainer>
      </Slide>
    </Modal>
  )
}

const getColors = (colorScheme: ColorSchemes) => {
  const schemes = {
    white: {
      background: colors.WHITE,
      backgroundDark: colors.DARK_MODE_80
    },
    grey: {
      background: colors.BLACK_10,
      backgroundDark: colors.DARK_MODE_80
    }
  }
  return schemes[colorScheme]
}

const ModalContainer = styled(Box)<{ colorScheme: ColorSchemes }>`
  ${({ theme, colorScheme }) => {
    const schemeColors = getColors(colorScheme)
    return `
      background: ${
        theme.palette.mode == 'dark' ? schemeColors.backgroundDark : schemeColors.background
      };

      ${theme.breakpoints.up('md')} {
          max-width: 430px;
          right: ${theme.spacing(2)};
          top: ${theme.spacing(2)};
          bottom: ${theme.spacing(2)};
          height: auto;
          border-radius: ${theme.shape.borderRadius};
      }
    `
  }}
`

export default FullScreenModal
