export const getError = (err: unknown, message?: string) => {
  if (err instanceof Error) message = err.message
  return message
}

export const attempt = async <T>(
  method: () => Promise<T>,
  onError?: (errorMessage?: string) => void
) => {
  try {
    return await method()
  } catch (err) {
    const message = getError(err)
    if (onError) {
      onError(message)
    } else {
      throw new Error(message)
    }
  }
}
