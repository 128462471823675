import { TypographyProps, Typography, ButtonProps, Button, styled } from '@mui/material'
import * as React from 'react'

import { VBox } from './basic-elements'
import { colors } from '../constants/colors'

export const FormBox = (props: React.PropsWithChildren) => (
  <VBox
    alignItems="center"
    justifyContent="center"
    textAlign="center"
    mx={4}
    width={480}
    maxWidth="100%"
    margin="auto"
    {...props}
  />
)

export const Title = (props: TypographyProps) => (
  <Typography
    variant="h3"
    color="textPrimary"
    fontFamily="Fields"
    sx={{ width: '20em', maxWidth: '100%' }}
    mb={3.75}
    {...props}
  />
)
export const Body = (props: TypographyProps) => (
  <Typography
    variant="body1"
    color="textPrimary"
    sx={{ width: '26em', maxWidth: '100%' }}
    {...props}
  />
)
export const Email = (props: TypographyProps) => (
  <Typography component="span" sx={{ textDecoration: 'underline' }} {...props} />
)

export const ButtonBox = (props: React.PropsWithChildren) => (
  <VBox
    justifyContent="center"
    alignItems="center"
    mt={4}
    mx="auto"
    width={345}
    maxWidth="100%"
    {...props}
  />
)

export const ButtonPrimary = (props: ButtonProps) => (
  <Button
    fullWidth
    variant="contained"
    disableElevation
    color="tertiary"
    sx={{ py: 1.5 }}
    {...props}
  />
)

export const ButtonSecondary = (props: ButtonProps) => (
  <Button variant="text" color="info" disableTouchRipple {...props} />
)
export const ButtonWarning = (props: ButtonProps) => (
  <Button
    fullWidth
    variant="contained"
    disableElevation
    color="warning"
    sx={{ py: 1.5 }}
    {...props}
  />
)

export const TextButtonBase = (props: ButtonProps) => (
  <Button
    variant="text"
    color="info"
    disableTouchRipple
    {...props}
    sx={{
      p: 0,
      lineHeight: '1em',
      fontSize: '1em',
      textDecoration: 'underline',
      backgroundColor: 'transparent!important',
      ...(props.sx || {})
    }}
  />
)

export const TextButton = styled(TextButtonBase)`
  ${({ theme }) => `
    color: ${theme.palette.mode == 'dark' ? colors.DARK_MODE_30 : colors.GEORGIAN_BAY_70};
  `}
`

export const BaseText = (props: TypographyProps) => (
  <Typography variant="body1" color="textPrimary" fontSize={24} {...props} />
)

export const ButtonText = styled(BaseText)<{ lightColor?: string; darkColor?: string }>`
  ${({ theme, darkColor, lightColor }) => `
    color: ${
      theme.palette.mode == 'dark'
        ? darkColor || colors.DARK_MODE_10
        : lightColor || colors.BLACK_50
    };
    .Mui-disabled & {
      color: ${colors.DARK_MODE_30};
    }
  `}
`

export const SmallText = (props: TypographyProps) => <BaseText fontSize={14} {...props} />

export const ErrorText = (props: TypographyProps) => (
  <SmallText textAlign="left" color="error" {...props} />
)
