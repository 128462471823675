import * as React from 'react'
import { Add } from '@mui/icons-material'
import { Typography, IconButton, styled, Box, CircularProgress } from '@mui/material'
import { WhiteBox, VBox, HBox, CenteredBox } from '../elements/basic-elements'
import { sanityString } from '../utils/sanity'
import { getFlatIconButtonStyle } from '../utils/mui'
import SwitchButton from './switch-button'
import { colors } from '../constants/colors'
import { T2 } from '../elements/text-elements'
import CloseIcon from '../images/icons/close-icon'

type ChipProps = {
  title: string
}
type AndOrFilterType = {
  title: string
  on: boolean
  onToggle: (isOn: boolean) => void
  onAddClick: () => void
  onDeleteClick: (item: ChipProps) => void
  loading?: boolean
  items: ChipProps[]
}
const AndOrFilter: React.FC<AndOrFilterType> = ({
  title,
  items,
  on,
  loading,
  onToggle,
  onAddClick,
  onDeleteClick
}) => {
  return (
    <WhiteBox>
      <VBox gap={1}>
        <HBox alignItems="center" justifyContent="space-between">
          <Typography variant="h4" color="textSecondary" sx={{ lineHeight: 1 }}>
            {title}
          </Typography>
          <SwitchButton on={on} onToggle={onToggle} textOff="and" textOn="or" />
        </HBox>
        <HBox flexWrap="wrap" gap={0.5} alignItems="center">
          {items.map(item => {
            const { title } = item
            return (
              <FilterChip
                label={sanityString(title)}
                onDelete={() => {
                  onDeleteClick(item)
                }}
              />
            )
          })}
          <IconButton
            sx={{
              ...getFlatIconButtonStyle('secondary.light', colors.BLACK_100),
              position: 'relative'
            }}
            onClick={() => !loading && onAddClick()}
          >
            {loading && (
              <CenteredBox
                sx={{
                  height: '100%',
                  width: '100%',
                  zIndex: 10000,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <CircularProgress color="tertiary" sx={{ width: '50%', height: '50%' }} size="xs" />
              </CenteredBox>
            )}
            <Add fontSize="small" sx={{ positon: 'relative', opacity: loading ? 0 : 1 }} />
          </IconButton>
        </HBox>
      </VBox>
    </WhiteBox>
  )
}

const FilterChip: React.FC<{ label: string; onDelete: () => void }> = ({ label, onDelete }) => {
  return (
    <FilterChipContainer>
      <Typography variant="body2" color="textSecondary">
        {label}
      </Typography>
      <IconButton
        size="small"
        sx={getFlatIconButtonStyle('tertiary.main', colors.BLACK_100)}
        onClick={onDelete}
      >
        <CloseIcon size={12} />
      </IconButton>
    </FilterChipContainer>
  )
}

const FilterChipContainer = styled(Box)`
  ${({ theme }) => `
    display: flex;
    gap: ${theme.spacing(1)};
    padding: ${theme.spacing(1)} ${theme.spacing(1.5)} ${theme.spacing(1)} ${theme.spacing(2)};
    border-radius: 9px;
    background: ${theme.palette.mode == 'dark' ? colors.DARK_MODE_80 : colors.BLACK_10};
`}
`
export default AndOrFilter
