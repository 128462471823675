import { Box, Button, styled } from '@mui/material'
import { colors } from '../constants/colors'
import { sitePaddingHorz, sitePaddingVert } from '../constants'
import { T3 } from './text-elements'

export const PageContentContainer = styled(Box)<{ pl?: number }>`
  ${({ theme, pl }) => {
    const paddingVert = theme.spacing(sitePaddingVert)
    const padding = theme.spacing(sitePaddingHorz)
    const paddingLeft = theme.spacing(sitePaddingHorz * (pl || 0))
    return `
      display: flex;
      width: 100%;
      padding: 0 ${theme.spacing(3)};

      ${theme.breakpoints.up('md')} {
        padding: ${paddingVert} ${padding} ${paddingVert} ${paddingLeft};
      }
    `
  }}
`

export const HeightBox = styled(Box)`
  height: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretch;
`

export const CenteredBox = styled(Box)<{ alignment?: 'center' | 'left' }>`
  ${({ alignment = 'center' }) => `
    position: absolute;
    left: ${alignment == 'center' ? '50%' : '0'};
    top: ${alignment == 'center' ? '50%' : '0'};
    transform: translate(-50%, -50%);
  `}
`

export const VBox = styled(Box)`
  display: flex;
  flex-direction: column;
`
export const HBox = styled(Box)`
  display: flex;
  flex-direction: row;
`
export const WhiteBox = styled(Box)`
  ${({ theme }) => `
    background: ${theme.palette.tertiary.main};
    padding: ${theme.spacing(2)};
    border-radius: ${theme.shape.borderRadius};
  `}
`

export const PillSmall = styled(Box)`
  display: inline-block;
  ${({ theme }) => `
    border-radius: 9px;
    padding: ${theme.spacing(0.5)} ${theme.spacing(1)};
`}
`
export const PillLarge = styled(Box)`
  ${({ theme }) => `
    border-radius: 100px;
    padding: ${theme.spacing(2)} ${theme.spacing(3)};
`}
`

export const GrayBox = styled(PillSmall)`
  ${({ theme }) => `
    background: ${theme.palette.mode == 'dark' ? colors.DARK_MODE_70 : colors.GLACIER_BLUE_70};
    color: ${theme.palette.mode == 'dark' ? colors.WHITE : colors.BLACK_100};
  `}
`

export const BlueBox = styled(PillSmall)`
  ${({ theme }) => `
    background: ${theme.palette.mode == 'dark' ? colors.DARK_MODE_50 : colors.GEORGIAN_BAY_10};
  `}
`

export const SquareButton = styled(Button)`
  width: 64px;
  height: 64px;
`

export const SmallButton = styled(Button)`
  ${({ theme }) => `
    font-size: 13px;
    padding: ${theme.spacing(1)} ${theme.spacing(2)};
  `}
`

export const GrayT3 = styled(T3)`
  display: inline-flex;
  align-items: center;
  ${({ theme }) => `
    gap: ${theme.spacing(0.5)};
    color: ${theme.palette.mode == 'dark' ? colors.DARK_MODE_10 : colors.BLACK_50};
  `}
`

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
})

export const OverflowInvisibleScroll = styled(Box)`
  overflow: scroll;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

export const PageContent = styled('main')`
  min-height: 100%;
  width: 100%;
  display: flex;
  ${({ theme }) => `
    padding: ${theme.spacing(2)};
  `}
`
