import { styled, Typography, useTheme } from '@mui/material'
import React from 'react'
import { colors } from '../constants/colors'
const Tagline = () => {
  const theme = useTheme()
  const isDarkMode = theme.palette.mode == 'dark'
  return (
    <TaglineEl color={isDarkMode ? 'textSecondary' : 'primary'} variant="h1">
      The world won’t unf*ck itself.
    </TaglineEl>
  )
}

const TaglineEl = styled(Typography)`
  max-width: 8em;
  ${({ theme }) => `
    ${theme.breakpoints.down('md')} {
      color: ${colors.WHITE}
    }
  `}
`

export default Tagline
