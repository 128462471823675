import { Box, Button, Typography } from '@mui/material'
import * as React from 'react'
import { colors } from '../constants/colors'
import { HBox, VBox } from '../elements/basic-elements'
import { LocationType } from '../types/location-types'
import { sanityString } from '../utils/sanity'
import AndOrFilter from './and-or-filter'
import ChipList from './chip-list'
import FilterUpdateScreen, { FilterUpdateProps } from './filter-update-screen'
import PartialScreenModal from './partial-screen-modal'

import { resultsState } from '../context/results-context'
import { filtersState } from '../state/filters-state'
import { ActionSorts } from '../utils/filters'

let clearFilterTimeout: NodeJS.Timeout
const FilterControls: React.FC<{ onSave: Function }> = ({ onSave }) => {
  const { changeFilters, filters } = resultsState()
  const { allFilters, filtersLoading, fetchAllFiltersIfNotFetched } = filtersState()

  const [filtersVerbsExclusiveTemp, setFiltersVerbsExclusiveTemp] = React.useState(
    Boolean(filters.verbsExclusive)
  )
  const [filtersNounsExclusiveTemp, setFiltersNounsExclusiveTemp] = React.useState(
    Boolean(filters.nounsExclusive)
  )
  const [filtersCobenefitsExclusiveTemp, setFiltersCobenefitsExclusiveTemp] = React.useState(
    Boolean(filters.cobenefitsExclusive)
  )
  const [filtersEndorsementsExclusiveTemp, setFiltersEndorsementsExclusiveTemp] = React.useState(
    Boolean(filters.endorsementsExclusive)
  )
  const [filtersIssuesExclusiveTemp, setFiltersIssuesExclusiveTemp] = React.useState(
    Boolean(filters.issuesExclusive)
  )

  // ==============================================
  // Filter options
  // ==============================================
  const [nounOptions, setNounOptions] = React.useState<Queries.SanityNoun[]>([])
  const [verbOptions, setVerbOptions] = React.useState<Queries.SanityVerb[]>([])
  const [cobenefitOptions, setCobenefitOptions] = React.useState<Queries.SanityCobenefits[]>([])
  const [endorsementOptions, setEndorsementOptions] = React.useState<Queries.SanityOrganization[]>(
    []
  )
  const [issueOptions, setIssueOptions] = React.useState<Queries.SanityIssue[]>([])

  // ==============================================
  // End Filter options
  // ==============================================
  // Modal stuff
  // ==============================================

  const [activeFilterMenu, setActiveFilterMenu] = React.useState<FilterUpdateProps>()
  const [showFilterMenu, setShowFilterMenu] = React.useState(false)
  const closeFilterMenu = () => setShowFilterMenu(false)

  // ==============================================
  // End Modal stuff
  // ==============================================
  // Active Filters
  // ==============================================

  // set initial filters from action data state

  const [verbFilters, setVerbFilters] = React.useState(filters.verbs || [])
  const [nounFilters, setNounFilters] = React.useState(filters.nouns || [])
  const [cobenefitsFilters, setCobenefitsFilters] = React.useState(filters.cobenefits || [])
  const [endorsementsFilters, setEndorsementsFilters] = React.useState(filters.endorsements || [])
  const [issuesFilters, setIssuesFilters] = React.useState(filters.issues || [])

  const [levelsFilters, setLevelsFilters] = React.useState<
    { title: LocationType; active: boolean }[]
  >([
    {
      title: 'global',
      active: Boolean(filters.levels && filters.levels?.includes('global'))
    },
    {
      title: 'federal',
      active: Boolean(filters.levels && filters.levels?.includes('federal'))
    },
    {
      title: 'state',
      active: Boolean(filters.levels && filters.levels?.includes('state'))
    },
    {
      title: 'city',
      active: Boolean(filters.levels && filters.levels?.includes('city'))
    },
    {
      title: 'local',
      active: Boolean(filters.levels && filters.levels?.includes('local'))
    }
  ])

  const sortLabels = {
    title: 'Title',
    impact: 'Impact Score',
    region: 'Region'
  }
  const [sortFilters, setSortFilters] = React.useState<
    { title: string; type: ActionSorts; active: boolean }[]
  >([
    {
      title: sortLabels['title'],
      type: 'title',
      active: Boolean(filters.sorts && filters.sorts?.includes('title'))
    },
    {
      title: sortLabels['impact'],
      type: 'impact',
      active: Boolean(filters.sorts && filters.sorts?.includes('impact'))
    },
    {
      title: sortLabels['region'],
      type: 'region',
      active: Boolean(filters.sorts && filters.sorts?.includes('region'))
    }
  ])

  const clearAllFilters = () => {
    setFiltersVerbsExclusiveTemp(false)
    setFiltersNounsExclusiveTemp(false)
    setFiltersCobenefitsExclusiveTemp(false)
    setFiltersEndorsementsExclusiveTemp(false)
    setFiltersIssuesExclusiveTemp(false)
    setVerbFilters([])
    setNounFilters([])
    setCobenefitsFilters([])
    setEndorsementsFilters([])
    setIssuesFilters([])
    setLevelsFilters(levelsFilters.map(filter => ({ ...filter, active: false })))
    setSortFilters(sortFilters.map(filter => ({ ...filter, active: false })))
  }

  const saveChanges = React.useCallback(async () => {
    const activeLevelFilters = levelsFilters
      .filter(({ active }) => active)
      .map(({ title }) => title)
    const activeSortFilters = sortFilters.filter(({ active }) => active).map(({ type }) => type)

    const newFilters = {
      nouns: nounFilters.length > 0 && nounFilters,
      verbs: verbFilters.length > 0 && verbFilters,
      cobenefits: cobenefitsFilters.length > 0 && cobenefitsFilters,
      endorsements: endorsementsFilters.length > 0 && endorsementsFilters,
      issues: issuesFilters.length > 0 && issuesFilters,
      levels: activeLevelFilters.length > 0 && activeLevelFilters,
      sorts: activeSortFilters.length > 0 && activeSortFilters,
      verbsExclusive: filtersVerbsExclusiveTemp,
      nounsExclusive: filtersNounsExclusiveTemp,
      cobenefitsExclusive: filtersCobenefitsExclusiveTemp,
      endorsementsExclusive: filtersEndorsementsExclusiveTemp,
      issuesExclusive: filtersIssuesExclusiveTemp
    }

    changeFilters(newFilters)

    onSave()
  }, [
    filters,
    verbFilters,
    nounFilters,
    cobenefitsFilters,
    endorsementsFilters,
    issuesFilters,
    levelsFilters,
    sortFilters,
    filtersVerbsExclusiveTemp,
    filtersNounsExclusiveTemp,
    filtersCobenefitsExclusiveTemp,
    filtersEndorsementsExclusiveTemp,
    filtersIssuesExclusiveTemp
  ])

  // ==============================================
  // End Active Filters
  // ==============================================
  // Effects
  // ==============================================

  // get filters on initial load
  React.useEffect(() => {
    fetchAllFiltersIfNotFetched()
  }, [])

  React.useEffect(() => {
    if (allFilters) {
      setNounOptions(allFilters.nouns || [])
      setVerbOptions(allFilters.verbs || [])
      setCobenefitOptions(allFilters.cobenefits || [])
      setEndorsementOptions(allFilters.endorsements || [])
      setIssueOptions(allFilters.issues || [])
    }
  }, [allFilters])

  // Modal opening/closing
  React.useEffect(() => {
    if (activeFilterMenu && !showFilterMenu) {
      setShowFilterMenu(true)

      // kill the timeout if user selects another filter menu before
      // the current one has closed
      clearTimeout(clearFilterTimeout)
    }
  }, [activeFilterMenu])

  React.useEffect(() => {
    if (activeFilterMenu && !showFilterMenu) {
      clearFilterTimeout = setTimeout(() => setActiveFilterMenu(false), 1000)
    }
  }, [showFilterMenu])

  // ==============================================
  // End Effects
  // ==============================================

  return (
    <VBox flex={1} pb={2} gap={2}>
      <VBox flex={1} px={2}>
        <HBox py={3} alignItems="center">
          <HBox sx={{ flex: 1 }}>
            <Button
              variant="text"
              color="info"
              sx={{ fontSize: 15, color: colors.BLACK_50 }}
              onClick={clearAllFilters}
            >
              Clear All
            </Button>
          </HBox>
          <Typography sx={{ fontSize: 28 }} color="textSecondary">
            Filters
          </Typography>
          <Box sx={{ flex: 1 }}></Box>
        </HBox>
        <VBox gap={1}>
          <AndOrFilter
            title="I want to"
            items={verbFilters}
            on={filtersVerbsExclusiveTemp}
            loading={!filters?.verbs && filtersLoading}
            onToggle={isOn => setFiltersVerbsExclusiveTemp(isOn)}
            onAddClick={() => {
              const options = verbOptions.map(({ title }) => ({
                title: sanityString(title),
                active: Boolean(verbFilters.find(verb => verb.title === title))
              }))
              setActiveFilterMenu({
                title: 'I want to',
                options,
                onSave: chips => {
                  const newVerbFilters = chips.map(({ title }) =>
                    verbOptions.find(v => v.title === title)
                  ) as Queries.SanityVerb[]
                  setVerbFilters(newVerbFilters)
                }
              })
            }}
            onDeleteClick={item => {
              const verbFiltersCopy = [...verbFilters]
              const index = verbFiltersCopy.findIndex(c => c.title === item.title)
              verbFiltersCopy.splice(index, 1)
              setVerbFilters(verbFiltersCopy)
            }}
          />
          <AndOrFilter
            title="subject"
            items={nounFilters}
            on={filtersNounsExclusiveTemp}
            loading={!filters?.nouns && filtersLoading}
            onToggle={isOn => setFiltersNounsExclusiveTemp(isOn)}
            onAddClick={() => {
              const options = nounOptions.map(({ title }) => ({
                title: sanityString(title),
                active: Boolean(nounFilters.find(noun => noun.title === title))
              }))
              setActiveFilterMenu({
                title: 'Subject',
                options,
                onSave: chips => {
                  const newNounFilters = chips.map(({ title }) =>
                    nounOptions.find(n => n.title === title)
                  ) as Queries.SanityNoun[]
                  setNounFilters(newNounFilters)
                }
              })
            }}
            onDeleteClick={item => {
              const nounFiltersCopy = [...nounFilters]
              const index = nounFiltersCopy.findIndex(c => c.title === item.title)
              nounFiltersCopy.splice(index, 1)
              setNounFilters(nounFiltersCopy)
            }}
          />
          <AndOrFilter
            title="co-benefits"
            items={cobenefitsFilters}
            on={filtersCobenefitsExclusiveTemp}
            loading={!filters?.cobenefits && filtersLoading}
            onToggle={isOn => setFiltersCobenefitsExclusiveTemp(isOn)}
            onAddClick={() => {
              const options = cobenefitOptions.map(({ title }) => ({
                title: sanityString(title),
                active: Boolean(cobenefitsFilters.find(cobenefits => cobenefits.title === title))
              }))
              setActiveFilterMenu({
                title: 'Co-benefits',
                options,
                onSave: chips => {
                  const newCobenefitFilters = chips.map(({ title }) =>
                    cobenefitOptions.find(c => c.title === title)
                  ) as Queries.SanityCobenefits[]
                  setCobenefitsFilters(newCobenefitFilters)
                }
              })
            }}
            onDeleteClick={item => {
              const cobenefitsFiltersCopy = [...cobenefitsFilters]
              const index = cobenefitsFiltersCopy.findIndex(c => c.title === item.title)
              cobenefitsFiltersCopy.splice(index, 1)
              setCobenefitsFilters(cobenefitsFiltersCopy)
            }}
          />

          <AndOrFilter
            title="endorsements"
            items={endorsementsFilters}
            on={filtersEndorsementsExclusiveTemp}
            loading={!filters?.endorsements && filtersLoading}
            onToggle={isOn => setFiltersEndorsementsExclusiveTemp(isOn)}
            onAddClick={() => {
              const options = endorsementOptions.map(({ title }) => ({
                title: sanityString(title),
                active: Boolean(
                  endorsementsFilters.find(endorsements => endorsements.title === title)
                )
              }))
              setActiveFilterMenu({
                title: 'Endorsements',
                options,
                onSave: chips => {
                  const newEndorsementsFilters = chips.map(({ title }) =>
                    endorsementOptions.find(v => v.title === title)
                  ) as Queries.SanityOrganization[]
                  setEndorsementsFilters(newEndorsementsFilters)
                }
              })
            }}
            onDeleteClick={item => {
              const endorsementsFiltersCopy = [...endorsementsFilters]
              const index = endorsementsFiltersCopy.findIndex(c => c.title === item.title)
              endorsementsFiltersCopy.splice(index, 1)
              setEndorsementsFilters(endorsementsFiltersCopy)
            }}
          />

          <AndOrFilter
            title="issues"
            items={issuesFilters}
            on={filtersIssuesExclusiveTemp}
            loading={!filters?.issues && filtersLoading}
            onToggle={isOn => setFiltersIssuesExclusiveTemp(isOn)}
            onAddClick={() => {
              const options = issueOptions.map(({ title }) => ({
                title: sanityString(title),
                active: Boolean(issuesFilters.find(issues => issues.title === title))
              }))
              setActiveFilterMenu({
                title: 'Issues',
                options,
                onSave: chips => {
                  const newIssuesFilters = chips.map(({ title }) =>
                    issueOptions.find(v => v.title === title)
                  ) as Queries.SanityIssue[]
                  setIssuesFilters(newIssuesFilters)
                }
              })
            }}
            onDeleteClick={item => {
              const issuesFiltersCopy = [...issuesFilters]
              const index = issuesFiltersCopy.findIndex(c => c.title === item.title)
              issuesFiltersCopy.splice(index, 1)
              setIssuesFilters(issuesFiltersCopy)
            }}
          />
          <ChipList
            title="levels"
            items={levelsFilters}
            onToggle={chip => {
              const levelsFiltersCopy = [...levelsFilters]
              const index = levelsFiltersCopy.findIndex(c => c.title === chip.title)
              levelsFiltersCopy[index].active = chip.active
              setLevelsFilters(levelsFiltersCopy)
            }}
          />
          <ChipList
            title="Sort By"
            items={sortFilters}
            onToggle={chip => {
              const sortFiltersCopy = [...sortFilters.map(filter => ({ ...filter, active: false }))]
              const index = sortFiltersCopy.findIndex(c => c.title === chip.title)
              sortFiltersCopy[index].active = chip.active
              setSortFilters(sortFiltersCopy)
            }}
          />
        </VBox>
      </VBox>
      <Box px={2}>
        <Button
          fullWidth
          variant="contained"
          disableElevation
          color="primary"
          onClick={saveChanges}
        >
          show actions
        </Button>
      </Box>

      <PartialScreenModal open={showFilterMenu} onClose={closeFilterMenu}>
        {activeFilterMenu && <FilterUpdateScreen {...activeFilterMenu} onClose={closeFilterMenu} />}
      </PartialScreenModal>
    </VBox>
  )
}

export default FilterControls
