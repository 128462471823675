import { User, onAuthStateChanged, signOut } from 'firebase/auth'
import * as React from 'react'
import { PropsWithChildren, useContext } from 'react'
import { ActionToolAccountWithId, UserActionHistory } from '../types/firebase-types'
import { auth } from '../async/firebase/config'
import { attempt } from '../async/utils'
import {
  getActionHistory,
  getFavorites,
  toggleActionTaken,
  toggleFavorite
} from '../utilities/account'
import { redirect } from '../utilities/routing'
import { handleAuthUser } from '../utilities/auth'

export const AuthContext = React.createContext<{
  authUser?: User | null
  account?: ActionToolAccountWithId
  userLoading?: boolean
  authRedirect?: string
  ignorePremiumRedirect?: boolean
  favorites?: string[] | null
  actionsTaken?: UserActionHistory[] | null
  setAccount: (account: ActionToolAccountWithId) => void
  setUserLoading: (loading: boolean) => void
  setAuthRedirect: (route?: string) => void
  setIgnorePremiumRedirect: (ignore: boolean) => void
  updateFavorite: (slug: string, favorited: boolean) => void
  updateActionTaken: (slug: string, action_taken: boolean) => void
  logout: () => void
}>({
  authUser: null,
  setAccount: () => {},
  setUserLoading: () => {},
  setAuthRedirect: () => {},
  setIgnorePremiumRedirect: () => {},
  updateFavorite: async () => {},
  updateActionTaken: async () => {},
  logout: () => {}
})

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [userLoading, setUserLoading] = React.useState(false)
  const [authRedirect, setAuthRedirect] = React.useState<string>()
  const [ignorePremiumRedirect, setIgnorePremiumRedirect] = React.useState(false)
  const [authUser, setAuthUser] = React.useState(auth?.currentUser || undefined)
  const [account, setAccount] = React.useState<ActionToolAccountWithId>()
  const [favorites, setFavorites] = React.useState(getFavorites(account))
  const [actionsTaken, setActionsTaken] = React.useState(getActionHistory(account))

  React.useEffect(() => {
    // when we auth gate a page, we often set a redirect to point the user
    // to when they become authenticated; this manages that process
    const unsubscribe = onAuthStateChanged(auth, async authUser => {
      setAuthUser(authUser || undefined)
      if (authUser && authUser.emailVerified && authRedirect) {
        redirect(authRedirect)
        setAuthRedirect(undefined)
      }
    })
    return () => unsubscribe()
  }, [authRedirect])

  React.useEffect(() => {
    const handle = async () => {
      setUserLoading(true)
      // note: account data is automatically updated by firebase observer
      // created during this method
      await attempt(
        async () => {
          await handleAuthUser(authUser, setAccount)
        },
        err => {
          // if an error during auth occurs, log out the user
          alert(
            'An error occured while authenticating your login credentials: ' +
              (err || '') +
              'You will be logged out as a result; please try logging in again.'
          )
          logout()
        }
      )
      setUserLoading(false)
    }
    handle()
  }, [authUser])

  const updateFavorite = React.useCallback(
    async (slug: string, favorited: boolean) => {
      setUserLoading(true)
      setFavorites((await toggleFavorite(slug, favorited, account)) || [])
      setUserLoading(false)
    },
    [account]
  )

  const updateActionTaken = React.useCallback(
    async (slug: string, action_taken: boolean) => {
      setUserLoading(true)
      setActionsTaken((await toggleActionTaken(slug, action_taken, account)) || [])
      setUserLoading(false)
    },
    [account]
  )

  // update account favorites on login/out
  React.useEffect(() => {
    setFavorites(getFavorites(account))
    setActionsTaken(getActionHistory(account))
  }, [account])

  const logout = async () => {
    setUserLoading(true)
    await signOut(auth)
      .then(() => {
        setAccount(undefined)
        setAuthUser(undefined)
        setUserLoading(false)
        setActionsTaken([])
        setFavorites([])
        // Sign-out successful.
      })
      .catch(() => {
        // An error happened.
      })
    setUserLoading(false)
  }

  return (
    <AuthContext.Provider
      value={{
        account,
        authUser,
        userLoading,
        authRedirect,
        favorites,
        ignorePremiumRedirect,
        actionsTaken,
        setAccount,
        setUserLoading,
        setAuthRedirect,
        setIgnorePremiumRedirect,
        updateFavorite,
        updateActionTaken,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const authState = () => useContext(AuthContext)
