import React from 'react'

const InvestIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3333 2C8.90137 2 6.23885 2.68027 4.45956 4.45956C2.68027 6.23885 2 8.90137 2 12.3333V20.3333C2 23.7653 2.68027 26.4278 4.45956 28.2071C6.23885 29.9864 8.90137 30.6667 12.3333 30.6667H20.3333C23.7653 30.6667 26.4278 29.9864 28.2071 28.2071C29.9864 26.4278 30.6667 23.7653 30.6667 20.3333V12.3333C30.6667 8.90137 29.9864 6.23885 28.2071 4.45956C26.4278 2.68027 23.7653 2 20.3333 2H12.3333ZM4 12.3333C4 9.09863 4.65307 7.09448 5.87377 5.87377C7.09448 4.65307 9.09863 4 12.3333 4H20.3333C23.568 4 25.5722 4.65307 26.7929 5.87377C28.0136 7.09448 28.6667 9.09863 28.6667 12.3333V20.3333C28.6667 23.568 28.0136 25.5722 26.7929 26.7929C25.5722 28.0136 23.568 28.6667 20.3333 28.6667H12.3333C9.09863 28.6667 7.09448 28.0136 5.87377 26.7929C4.65307 25.5722 4 23.568 4 20.3333V12.3333ZM23.043 12.2929C23.2643 12.5142 23.3602 12.8134 23.3307 13.1022V15.6667C23.3307 16.219 22.883 16.6667 22.3307 16.6667C21.7784 16.6667 21.3307 16.219 21.3307 15.6667V15.4194L17.443 19.3071C17.2312 19.519 16.9355 19.6247 16.6374 19.5951C16.3393 19.5656 16.0701 19.404 15.9039 19.1547L14.4472 16.9696L11.043 20.3738C10.6525 20.7643 10.0194 20.7643 9.62883 20.3738C9.23831 19.9832 9.23831 19.3501 9.62883 18.9596L13.8955 14.6929C14.1073 14.481 14.403 14.3753 14.7011 14.4049C14.9993 14.4344 15.2685 14.596 15.4347 14.8453L16.8914 17.0304L19.9217 14H19.6641C19.1118 14 18.6641 13.5523 18.6641 13C18.6641 12.4477 19.1118 12 19.6641 12H22.3307L22.3333 12C22.5901 11.9993 22.8471 12.097 23.043 12.2929Z"
      fill="white"
    />
  </svg>
)

export default InvestIcon
