import React from 'react'

const FilterIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5689 6.5C9.57729 6.5 8.77344 7.30385 8.77344 8.29545C8.77344 9.28706 9.57729 10.0909 10.5689 10.0909C11.5605 10.0909 12.3643 9.28706 12.3643 8.29545C12.3643 7.30385 11.5605 6.5 10.5689 6.5ZM4.75 9.0459H7.35926C7.69893 10.5044 9.00702 11.5909 10.5689 11.5909C12.3889 11.5909 13.8643 10.1155 13.8643 8.29545C13.8643 6.47543 12.3889 5 10.5689 5C9.0067 5 7.69839 6.087 7.35906 7.5459H4.75C4.33579 7.5459 4 7.88168 4 8.2959C4 8.71011 4.33579 9.0459 4.75 9.0459ZM14.9375 7.5459C14.5233 7.5459 14.1875 7.88168 14.1875 8.2959C14.1875 8.71011 14.5233 9.0459 14.9375 9.0459H19.3011C19.7154 9.0459 20.0511 8.71011 20.0511 8.2959C20.0511 7.88168 19.7154 7.5459 19.3011 7.5459H14.9375ZM16.6926 17.0459C16.3529 18.5044 15.0448 19.5909 13.483 19.5909C11.6629 19.5909 10.1875 18.1155 10.1875 16.2955C10.1875 14.4754 11.6629 13 13.483 13C15.0451 13 16.3535 14.087 16.6928 15.5459H19.2997C19.7139 15.5459 20.0497 15.8817 20.0497 16.2959C20.0497 16.7101 19.7139 17.0459 19.2997 17.0459H16.6926ZM4 16.2959C4 15.8817 4.33579 15.5459 4.75 15.5459H9.11364C9.52785 15.5459 9.86364 15.8817 9.86364 16.2959C9.86364 16.7101 9.52785 17.0459 9.11364 17.0459H4.75C4.33579 17.0459 4 16.7101 4 16.2959ZM11.6875 16.2955C11.6875 15.3039 12.4914 14.5 13.483 14.5C14.4746 14.5 15.2784 15.3039 15.2784 16.2955C15.2784 17.2871 14.4746 18.0909 13.483 18.0909C12.4914 18.0909 11.6875 17.2871 11.6875 16.2955Z"
      fill="white"
    />
  </svg>
)

export default FilterIcon
