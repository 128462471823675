import { Box, Switch, SwitchProps, Tooltip, Typography, styled } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import { colors } from '../constants/colors'
import { HBox } from '../elements/basic-elements'
import { T3 } from '../elements/text-elements'
import { InfoOutlined } from '@mui/icons-material'
import TooltipTitle from './tooltip-title'

type FormSwitchProps = {
  text: string
  description?: string
  value: boolean
  disabled?: boolean
  disabledText?: string
  allowDisabledCheck?: boolean
  onChange?: (change: boolean) => void
}

const FormSwitch: FC<FormSwitchProps> = props => {
  const [checked, setChecked] = useState(props.value)
  useEffect(() => {
    if (props.disabled && !props.allowDisabledCheck && checked) {
      updateChecked(false)
    }
  }, [props.disabled, checked, props.allowDisabledCheck])

  useEffect(() => {
    setChecked(props.value)
  }, [props.value])

  const updateChecked = (checked: boolean) => {
    setChecked(checked)
    props.onChange && props.onChange(checked)
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateChecked(e.target.checked)
  }

  return (
    <HBox alignItems="center" justifyContent="space-between" gap={1} py={0.5}>
      <Box>
        <Typography color="textSecondary">{props.text}</Typography>
        {props.description && <DescriptionText>{props.description}</DescriptionText>}
      </Box>
      <Tooltip
        placement="top"
        disableFocusListener={!props.disabledText}
        title={props.disabledText && <TooltipTitle description={props.disabledText} />}
      >
        <Box>
          <IOSSwitch disabled={props.disabled} checked={checked} onChange={handleChange} />
        </Box>
      </Tooltip>
    </HBox>
  )
}

const DescriptionText = styled(T3)`
  ${({ theme }) => `
    color: ${theme.palette.mode == 'dark' ? colors.DARK_MODE_50 : colors.BLACK_50};
  `}
`

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary,
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: 'red',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      cursor: 'not-allowed',
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : colors.DARK_MODE_30,
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}))
export default FormSwitch
