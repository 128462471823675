import { useEffect, useRef, useState } from 'react'
import { hasValue } from '.'
import { FirestoreCollections } from '../types/firebase-types'

export const usePrevious = <T>(value: T) => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
