import React from 'react'

const HighFive = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="94"
    height="96"
    viewBox="0 0 94 96"
    fill="none"
  >
    <path
      d="M20.7881 67.6395C20.7881 67.6395 26.6011 60.648 25.8972 55.2517C25.1932 49.8555 21.524 44.9718 23.1592 43.2469C24.7944 41.5219 28.9064 46.278 28.9064 46.278C28.9064 46.278 29.6462 38.7001 28.6232 30.9668C28.2862 28.4282 28.7967 25.5825 31.6763 26.0492C31.6763 26.0492 33.6884 23.0021 36.9389 25.8338C36.9389 25.8338 40.1295 21.646 43.1387 27.5767C43.1387 27.5767 44.0082 24.5854 47.4163 26.6215C50.8243 28.6575 51.8034 36.2573 51.3567 45.596C50.91 54.9347 48.5649 64.7958 45.0551 71.6019C43.0086 75.5869 40.7486 79.4585 38.285 83.2C38.285 83.2 18.1319 80.2666 20.7881 67.6395Z"
      fill="#6FB1C8"
    />
    <path
      d="M46.0284 27.3131C46.0284 27.3131 45.9267 24.8204 48.7564 24.6449C50.5113 24.5352 50.6987 25.7337 50.6987 25.7337C50.6987 25.7337 52.643 23.6039 54.0888 24.4554C55.5346 25.3069 55.8497 25.3428 55.8497 25.3428C55.8497 25.3428 58.6814 23.3487 59.838 24.0466C60.9946 24.7446 61.8002 25.3428 61.8002 25.3428C61.8002 25.3428 62.9967 23.2649 65.6849 25.0098C65.6849 25.0098 63.7785 31.7621 63.6748 35.4892C63.5711 39.2163 64.1235 45.5916 64.1235 45.5916C64.1235 45.5916 68.7101 42.1397 70.8917 44.3273C73.0733 46.5149 70.2037 51.4664 68.1816 53.4746C66.1595 55.4827 64.8932 60.6536 66.6361 63.3218C68.379 65.9899 70.4031 70.4848 70.4031 70.4848C70.4031 70.4848 66.4985 83.419 55.5366 83.8158C55.5366 83.8158 50.8822 81.1416 49.1672 78.8444C47.4522 76.5471 45.5079 70.5905 45.5079 70.5905C45.5079 70.5905 37.4196 56.041 46.0284 27.3131Z"
      fill="#6FB1C8"
    />
    <path
      d="M20.1562 67.2061C27.2894 58.2523 30.6635 46.397 29.3095 35.0322C29.0981 33.2574 28.7771 31.4946 28.7113 29.7098C28.6794 28.9281 28.7112 28.1145 29.0383 27.4085C29.3653 26.7026 30.4541 25.2907 31.6746 26.0425"
      stroke="#222222"
      strokeWidth="1.59533"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.6501 44.7941C34.8176 39.1645 33.5095 33.8261 32.315 28.3302C32.1295 27.4807 31.9719 26.5574 32.3409 25.7697C32.7756 24.8444 34.3211 24.2123 35.2882 24.5413C36.6562 25.0079 36.8517 25.7996 37.083 26.0429"
      stroke="#222222"
      strokeWidth="1.59533"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.6689 45.1711C40.3079 40.8739 39.5957 36.6134 38.5392 32.4324C38.1842 31.0225 37.7894 29.6226 37.3945 28.2227C37.0854 27.1279 37.1951 25.6203 37.9768 24.8027C38.8801 23.8714 40.6211 24.2623 41.5863 25.1298C42.9144 26.3263 43.0978 27.6185 43.3431 28.121"
      stroke="#222222"
      strokeWidth="1.59533"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M44.9889 46.1727C45.0405 41.0594 44.5546 35.9549 43.5392 30.9432C43.2221 29.3798 42.9828 27.4415 44.2571 26.4843C44.9511 25.9638 45.9322 25.9339 46.7378 26.2569C47.5435 26.58 48.1916 27.2082 48.7081 27.9041C50.3034 30.0439 50.7561 32.7998 51.0193 35.4501C52.5741 51.6315 48.56 67.859 39.6406 81.4495"
      stroke="#222222"
      strokeWidth="1.59533"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.9031 46.278C28.2679 44.9101 27.2055 43.7855 25.876 43.0734C25.4437 42.8206 24.958 42.673 24.4581 42.6427C24.2089 42.6298 23.9602 42.6776 23.7335 42.7818C23.5067 42.8859 23.3085 43.0435 23.1559 43.2409C22.9622 43.5627 22.8679 43.9346 22.8847 44.3098C22.8668 45.7376 23.5109 47.0757 24.0533 48.3958C24.8968 50.4558 25.525 52.6394 25.533 54.8649C25.533 56.6597 25.1441 58.4165 24.7532 60.1614"
      stroke="#222222"
      strokeWidth="1.59533"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.2782 66.2011C53.4054 63.9123 53.3862 61.3857 54.2243 59.084C54.9183 57.2074 56.1487 55.5842 57.3611 53.9948C58.9266 51.9408 60.496 49.8849 62.2728 48.0123C63.9379 46.2595 65.8623 44.6322 68.1715 43.9382C68.7026 43.7507 69.2717 43.6966 69.8287 43.7807C71.2047 44.0619 71.9645 45.6732 71.7989 47.0691C71.6334 48.465 70.8018 49.6735 69.9742 50.8141C68.6003 52.7305 67.1824 54.6948 66.5842 56.9741C65.5871 60.763 66.8494 64.2169 70.9195 69.998"
      stroke="#222222"
      strokeWidth="1.59533"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M64.1025 45.5895C63.0928 39.2434 63.4649 32.7542 65.1933 26.5651C65.319 26.1105 65.4486 25.596 65.2153 25.1852C64.9361 24.6926 64.268 24.5869 63.7037 24.6487C62.3796 24.7983 61.1871 25.586 60.3236 26.601C59.4601 27.6161 58.8918 28.8465 58.4191 30.0928C56.8626 34.2171 56.2645 38.6414 56.6703 43.031"
      stroke="#222222"
      strokeWidth="1.59533"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M61.3292 25.3401C60.9583 24.4287 59.9333 23.9063 58.9362 23.9222C57.9391 23.9382 57.0258 24.4068 56.2421 25.0051C53.7952 26.8756 52.5629 29.9626 52.0803 33.0037C51.5977 36.0448 51.7572 39.1537 51.5 42.2227"
      stroke="#222222"
      strokeWidth="1.59533"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M55.51 25.3053C55.0852 24.5774 54.1799 24.2185 53.3404 24.2903C52.5008 24.362 51.3163 25.1697 50.6881 25.7321C49.8705 26.4639 49.2344 27.457 49.1406 28.5518"
      stroke="#222222"
      strokeWidth="1.59533"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.7812 42.9004C54.2939 42.4039 56.8125 43.4987 59.3491 43.8616"
      stroke="#222222"
      strokeWidth="1.59533"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M50.4342 25.1083C49.8443 24.7228 49.1333 24.5678 48.4364 24.6726C47.7396 24.7774 47.1057 25.1347 46.6552 25.6766C46.5666 25.7917 46.4885 25.9145 46.4219 26.0435"
      stroke="#222222"
      strokeWidth="1.59533"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45.5469 71.1016C46.1451 73.2393 47.6806 77.1798 49.1464 78.8429C51.5094 81.5271 53.7828 82.5441 55.9026 83.1942"
      stroke="#222222"
      strokeWidth="1.59533"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M79.4609 40.5955C81.5477 39.6063 83.695 38.7504 85.8901 38.033C84.8837 37.2962 83.7957 36.678 82.6476 36.1904C84.5623 34.6452 86.7291 33.4417 89.0529 32.6328C90.1164 33.4225 91.2039 34.1783 92.3153 34.9002L88.0658 36.4636C89.6259 37.3395 91.1089 38.3462 92.4988 39.4728C88.2148 40.3768 83.8364 40.7539 79.4609 40.5955Z"
      fill="white"
      stroke="#222222"
      strokeWidth="1.59533"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.1796 32.2818C17.3967 30.8145 15.7088 29.2352 14.1264 27.5537C13.8875 28.7781 13.7911 30.0261 13.8392 31.2728C11.6254 30.1974 9.6132 28.7491 7.89062 26.9913C8.15651 25.6938 8.37786 24.3882 8.55467 23.0747L11.7793 26.2535C11.9071 24.4689 12.186 22.6984 12.6128 20.9609C15.2548 24.4529 17.4601 28.2547 19.1796 32.2818Z"
      fill="white"
      stroke="#222222"
      strokeWidth="1.59533"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M75.9062 31.3375L79.8108 28.9844"
      stroke="#222222"
      strokeWidth="1.59533"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M70.3759 16.1797L68.8125 18.8499"
      stroke="#222222"
      strokeWidth="1.59533"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46.0814 10.7109L46.0156 15.8579"
      stroke="#222222"
      strokeWidth="1.59533"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.7734 15.7891L29.4665 18.0046"
      stroke="#222222"
      strokeWidth="1.59533"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.7969 50.9688H16.2488"
      stroke="#222222"
      strokeWidth="1.59533"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M78.1875 60.9844L80.7261 63.3255"
      stroke="#222222"
      strokeWidth="1.59533"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.0781 83.1953C54.0781 83.1953 63.5205 80.563 70.8291 69.0547L83.9248 77.149C83.9248 77.149 74.8773 91.9058 67.4171 95.0068L54.0781 83.1953Z"
      fill="#222222"
      stroke="#222222"
      strokeWidth="1.59533"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.5393 65.8984C19.5393 65.8984 29.2609 80.5416 40.2926 81.7321L26.9197 95.0053C26.9197 95.0053 7.87344 86.0754 5.67188 74.3517L19.5393 65.8984Z"
      fill="#222222"
      stroke="#222222"
      strokeWidth="1.59533"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M91.1888 14.2177L88.6094 9.75L80.679 14.3286L83.2584 18.7963L91.1888 14.2177Z"
      fill="white"
      stroke="#222222"
      strokeWidth="1.59533"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.9479 11.7784L36.3047 9.01562L31.4007 1.28222L27.0439 4.045L31.9479 11.7784Z"
      fill="white"
      stroke="#222222"
      strokeWidth="1.59533"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M81.7833 56.8635C83.4111 56.8635 84.7307 55.5439 84.7307 53.9161C84.7307 52.2883 83.4111 50.9688 81.7833 50.9688C80.1555 50.9688 78.8359 52.2883 78.8359 53.9161C78.8359 55.5439 80.1555 56.8635 81.7833 56.8635Z"
      fill="#FF4438"
      stroke="#222222"
      strokeWidth="1.59533"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.44738 44.7307C6.07518 44.7307 7.39477 43.4111 7.39477 41.7833C7.39477 40.1555 6.07518 38.8359 4.44738 38.8359C2.81959 38.8359 1.5 40.1555 1.5 41.7833C1.5 43.4111 2.81959 44.7307 4.44738 44.7307Z"
      fill="#FF4438"
      stroke="#222222"
      strokeWidth="1.59533"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M61.338 12.5666C62.9658 12.5666 64.2853 11.2471 64.2853 9.61926C64.2853 7.99147 62.9658 6.67188 61.338 6.67188C59.7102 6.67188 58.3906 7.99147 58.3906 9.61926C58.3906 11.2471 59.7102 12.5666 61.338 12.5666Z"
      fill="#FF4438"
      stroke="#222222"
      strokeWidth="1.59533"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default HighFive
