export const getImageTypeFromBase64String = (image: string) => {
  return image.substring('data:image/'.length, image.indexOf(';base64'))
}

export const parseBase64Data = (image: string) => {
  return image.replace(/^data:\w+\/\w+;base64,/, '')
}

export const getJpgOrPng = (image: string) => {
  return image === 'jpeg' || image === 'jpg'
    ? 'jpg'
    : image === 'png'
    ? 'png'
    : ''
}

export const getFileSizeInKb = (image: string, type: string) => {
  const stringLength = image.length - `data:image/${type};base64,`.length
  const sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812
  return sizeInBytes / 1000
}

export const validateAvatar = (base64string: string) => {
  const image = base64string
  const type = getImageTypeFromBase64String(image)

  if (type !== 'jpeg' && type !== 'jpg' && type !== 'png') {
    return {
      valid: false,
      reason: 'Not a valid file type. Must be a .jpg or .png'
    }
  }

  const imageString = parseBase64Data(image)

  var sizeInKb = getFileSizeInKb(imageString, type)

  if (sizeInKb >= 5000) {
    return {
      valid: false,
      reason: 'Uploaded image must be below 5mb'
    }
  }
  return { valid: true }
}
