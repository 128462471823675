import React, { useEffect } from 'react'
import { Box, Button, CircularProgress, styled } from '@mui/material'
import { navigate } from 'gatsby'

import { actionDataState } from '../context/action-data-context'
import { candidateDataState } from '../context/candidate-data-context'
import { resultsState } from '../context/results-context'
import { VBox } from '../elements/basic-elements'

import ActionsList from './actions-list'
import CandidatesList from './candidates-list'
import LoadingScreen from './loading-screen'
import SearchResultsDefaultHeader from './search-results-default-header'
import SearchResultsExploreHeader from './search-results-explore-header'
import { pageState } from '../context/page-context'

let lastScroll = 0
let lastWheel = 0
let blockWheel = false

const SearchResults: React.FC<React.PropsWithChildren<{ action?: Queries.SanityAction }>> = ({
  children
}) => {
  const { pageContentRef } = pageState()
  const { loading: actionPageLoading, init } = resultsState()

  const [activeList, setActiveList] = React.useState(0)
  const [tempOffset, setTempOffset] = React.useState(0)
  const [captureMovement, setCaptureMovement] = React.useState(false)

  const { totalActions = 0 } = actionDataState()
  const { totalCandidates = 0 } = candidateDataState()

  const results = totalActions + totalCandidates
  const hasResults = results > 0
  const isLoading = actionPageLoading

  // ------------------------------------------------
  // Scroll State for when multiple result lists exist
  // ------------------------------------------------

  const numLists = [totalActions, totalCandidates].filter(count => count > 0).length
  const oneList = numLists === 1

  const parentRef = React.useRef<HTMLElement>(null)
  const listScrollContainer = React.useRef<HTMLElement>(null)

  React.useEffect(() => {
    const scrollContainer = listScrollContainer.current
    if (scrollContainer) {
      const lists = Array.from(scrollContainer.childNodes)
      const listsToOffset = lists.splice(0, activeList)
      const distance = listsToOffset.reduce((total, curr) => {
        return total + curr.offsetHeight
      }, 0)

      scrollContainer.style.transform = `translateY(-${distance}px)`
      scrollContainer.style.overflow = 'hidden'
      requestAnimationFrame(() => {
        scrollContainer.style.overflow = 'unset'
      })
    }
  }, [activeList, numLists, listScrollContainer.current])

  const handleTouchStart = (e: TouchEvent) => {
    // e.preventDefault()

    lastScroll = e.touches[0].clientY
    setCaptureMovement(true)
  }

  const handleTouchMove = (e: TouchEvent) => {
    // e.preventDefault()
    trackMovement(e.touches[0].clientY)
  }

  const handleMouseDown = (e: MouseEvent) => {
    e.preventDefault()

    lastScroll = e.clientY
    setCaptureMovement(true)
  }
  const handleMoveEnd = () => {
    setCaptureMovement(false)
    setTempOffset(0)
  }

  const trackMovement = React.useCallback(
    (amount: number) => {
      const diff = amount - lastScroll
      if (diff < -25) {
        handleMoveEnd()
        handlePageDown()
      } else if (diff > 25) {
        handleMoveEnd()
        handlePageUp()
      } else {
        setTempOffset(diff)
      }
    },
    [activeList, numLists]
  )

  const handleMove = React.useCallback(
    (e: MouseEvent) => {
      e.preventDefault()
      trackMovement(e.clientY)
    },
    [activeList, numLists]
  )

  const handleWheel = React.useCallback(
    (e: WheelEvent) => {
      if (blockWheel) {
        if (Math.abs(e.deltaY) < 5) {
          blockWheel = false
        } else {
          return
        }
      }
      if (e.deltaY < -25) {
        blockWheel = true
        handlePageUp()
      } else if (e.deltaY > 25) {
        blockWheel = true
        handlePageDown()
      }
    },
    [activeList, numLists]
  )

  const handlePageDown = React.useCallback(() => {
    const lastPage = activeList >= numLists - 1
    if (!lastPage) {
      setActiveList(activeList + 1)
    }
  }, [activeList, numLists])

  const handlePageUp = React.useCallback(() => {
    if (activeList !== 0) {
      setActiveList(activeList - 1)
    }
  }, [activeList])

  useEffect(() => {
    const ref = parentRef.current
    if (ref) {
      const lockScroll = (e: Event) => {
        e.preventDefault()
        if (e.target) {
          e.target.scrollLeft = 0
        }
      }
      ref.addEventListener('scroll', lockScroll)
      return () => ref.removeEventListener('scroll', lockScroll)
    }
  }, [parentRef.current])

  const isExplorePage = () => {
    return window.location.pathname.split('/').filter(p => p)[0] === 'explore'
  }

  return (
    <Content>
      {isLoading && <LoadingScreen loading={true} hideProgress={true} />}
      <React.Fragment>
        {isExplorePage() ? <SearchResultsExploreHeader /> : <SearchResultsDefaultHeader />}
        {isLoading ? (
          <VBox alignItems="center" justifyContent="center" flex={1}>
            <CircularProgress color="tertiary" />
          </VBox>
        ) : (
          <ResultsListsContainer
            onTouchStart={handleTouchStart}
            onTouchMove={!oneList && captureMovement ? handleTouchMove : undefined}
            onTouchEnd={handleMoveEnd}
            onMouseDown={handleMouseDown}
            onMouseMove={!oneList && captureMovement ? handleMove : undefined}
            onMouseUp={handleMoveEnd}
            onWheel={handleWheel}
            sx={{ pb: oneList ? 0 : 2 }}
            ref={parentRef}
          >
            <ResultsListsScrollContainer ref={listScrollContainer}>
              {init && !hasResults && !isLoading && (
                <VBox justifyContent="center" alignItems="center" mt={2} flex={1}>
                  <Button
                    variant="contained"
                    disableElevation
                    color="secondary"
                    onClick={() => navigate('/')}
                  >
                    Refine search
                  </Button>
                </VBox>
              )}
              {totalActions > 0 && (
                <ResultsList only={oneList}>
                  <ActionsList title={totalCandidates > 0 ? 'Actions' : undefined} />
                </ResultsList>
              )}
              {totalCandidates > 0 && (
                <ResultsList only={oneList}>
                  <CandidatesList title={totalActions > 0 ? 'Candidates' : undefined} />
                </ResultsList>
              )}
            </ResultsListsScrollContainer>
          </ResultsListsContainer>
        )}

        {children}
      </React.Fragment>
    </Content>
  )
}

const Content = styled(Box)`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  touch-action: none;
`

const ResultsListsContainer = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  touch-action: none;
  overflow: hidden;
`

const ResultsListsScrollContainer = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: transform 0.5s cubic-bezier(0, 0, 0, 1);
  touch-action: none;
`

const ResultsList = styled(Box)<{ only?: boolean }>`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  ${({ theme }) => `
    padding: ${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(2)};
  ${theme.breakpoints.up('md')} {
    padding: ${theme.spacing(0)} ${theme.spacing(3.5)} ${theme.spacing(2)};
    }
  `}
  touch-action: none;
  cursor: ${({ only }) => (only ? 'initial' : 'grab')};
`

export default SearchResults
