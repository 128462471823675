import React from 'react'

const PurchaseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 11.6667C4.5 9.52393 5.10718 8.15797 6.01378 7.31613C6.93381 6.46182 8.31996 6 10.1667 6H23.5C25.3467 6 26.7329 6.46182 27.6529 7.31613C28.5595 8.15797 29.1667 9.52393 29.1667 11.6667V21C29.1667 23.1427 28.5595 24.5087 27.6529 25.3505C26.7329 26.2048 25.3467 26.6667 23.5 26.6667H10.1667C8.31996 26.6667 6.93381 26.2048 6.01378 25.3505C5.10718 24.5087 4.5 23.1427 4.5 21V11.6667ZM10.1667 4C8.01337 4 6.06619 4.53818 4.65288 5.85054C3.22615 7.17536 2.5 9.14273 2.5 11.6667V21C2.5 23.5239 3.22615 25.4913 4.65288 26.8161C6.06619 28.1285 8.01337 28.6667 10.1667 28.6667H23.5C25.6533 28.6667 27.6005 28.1285 29.0138 26.8161C30.4405 25.4913 31.1667 23.5239 31.1667 21V11.6667C31.1667 9.14273 30.4405 7.17536 29.0138 5.85054C27.6005 4.53818 25.6533 4 23.5 4H10.1667ZM13.8359 16.3334C13.8359 14.6765 15.1791 13.3334 16.8359 13.3334C18.4928 13.3334 19.8359 14.6765 19.8359 16.3334C19.8359 17.9902 18.4928 19.3334 16.8359 19.3334C15.1791 19.3334 13.8359 17.9902 13.8359 16.3334ZM16.8359 11.3334C14.0745 11.3334 11.8359 13.5719 11.8359 16.3334C11.8359 19.0948 14.0745 21.3334 16.8359 21.3334C19.5974 21.3334 21.8359 19.0948 21.8359 16.3334C21.8359 13.5719 19.5974 11.3334 16.8359 11.3334ZM8.16406 12C8.71635 12 9.16406 12.4477 9.16406 13V19.6667C9.16406 20.219 8.71635 20.6667 8.16406 20.6667C7.61178 20.6667 7.16406 20.219 7.16406 19.6667V13C7.16406 12.4477 7.61178 12 8.16406 12ZM26.5 13C26.5 12.4477 26.0523 12 25.5 12C24.9477 12 24.5 12.4477 24.5 13V19.6667C24.5 20.219 24.9477 20.6667 25.5 20.6667C26.0523 20.6667 26.5 20.219 26.5 19.6667V13Z"
      fill="white"
    />
  </svg>
)

export default PurchaseIcon
