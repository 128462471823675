import Stripe from 'stripe'
import { fetchNetlify } from '../netlify'

export const getCustomerFromEmail = async (email: string) =>
  await fetchNetlify<{ customer: Stripe.Customer }>(
    `/.netlify/functions/stripe-get-customer?email=${email}`
  )

export const testVerification = async (email: string, name: string) =>
  await fetchNetlify(`/.netlify/functions/stripe-verification-test?email=${email}&name=${name}`)

export const checkVerification = async (session_id: string) => {
  await fetchNetlify(
    `/.netlify/functions/stripe-check-verification-session?session_id=${session_id}`
  )
}

export const cancelTest = async (email: string) =>
  await fetchNetlify(`/.netlify/functions/stripe-verification-cancel-test?email=${email}`)

export const createStripeVerificationSession = async (wcid_user_id: string) =>
  await fetchNetlify<string>(
    `/.netlify/functions/stripe-create-verification-session?wcid_user_id=${wcid_user_id}`
  )

export const cancelStripeVerificationSession = async (session_id: string) => {
  await fetchNetlify(
    `/.netlify/functions/stripe-cancel-verification-session?session_id=${session_id}`
  )
}
