import React from 'react'

const PartyHat = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="92"
    viewBox="0 0 80 92"
    fill="none"
  >
    <path
      d="M63.0547 61.0922C65.4783 60.9966 67.9055 61.0549 70.3218 61.2667C69.6505 60.1403 68.8519 59.0948 67.9417 58.1509C70.4299 57.4489 73.0204 57.1806 75.5996 57.3575C76.3004 58.5594 77.0395 59.7376 77.817 60.8919L73.0569 60.6539C74.2038 62.1423 75.2223 63.7255 76.1014 65.3862C71.5935 64.4928 67.212 63.0507 63.0547 61.0922Z"
      fill="white"
      stroke="#222222"
      strokeWidth="1.58671"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.3871 15.6037C35.965 13.2147 35.6947 10.8014 35.5779 8.3782C34.5529 9.19475 33.6252 10.1265 32.8131 11.1549C31.7787 8.78596 31.1601 6.25653 30.9844 3.67757C32.0792 2.82075 33.1463 1.92822 34.1856 1L34.5822 5.7403C35.902 4.40205 37.3332 3.17852 38.8604 2.08293C38.5912 6.67394 37.7606 11.2147 36.3871 15.6037Z"
      fill="white"
      stroke="#222222"
      strokeWidth="1.58671"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M59.8125 51.1898L68.1705 50.0156"
      stroke="#222222"
      strokeWidth="1.58671"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M64.3272 33.7578C63.4565 34.4044 58.7301 38.6588 57.8594 39.3113"
      stroke="#222222"
      strokeWidth="1.58671"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.3692 24.9922L37.1875 29.9388"
      stroke="#222222"
      strokeWidth="1.58671"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.5255 17.5781L52.3438 22.5366"
      stroke="#222222"
      strokeWidth="1.58671"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.3203 15.8359L25.4703 24.2554"
      stroke="#222222"
      strokeWidth="1.58671"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.5 34.0156L4.83805 35.4377"
      stroke="#222222"
      strokeWidth="1.58671"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.3516 68.375L61.8411 71.6833"
      stroke="#222222"
      strokeWidth="1.58671"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M78.356 43.9104L77.7031 38.5312L68.1538 39.6902L68.8066 45.0694L78.356 43.9104Z"
      fill="white"
      stroke="#222222"
      strokeWidth="1.58671"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.675 26.038L15.8125 22.5391L9.60101 15.194L5.46352 18.6929L11.675 26.038Z"
      fill="white"
      stroke="#222222"
      strokeWidth="1.58671"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M73.2679 20.6687C74.9778 20.6687 76.364 19.2825 76.364 17.5726C76.364 15.8627 74.9778 14.4766 73.2679 14.4766C71.558 14.4766 70.1719 15.8627 70.1719 17.5726C70.1719 19.2825 71.558 20.6687 73.2679 20.6687Z"
      fill="#FF4438"
      stroke="#222222"
      strokeWidth="1.58671"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.7367 7.19214C15.4466 7.19214 16.8327 5.80597 16.8327 4.09606C16.8327 2.38615 15.4466 1 13.7367 1C12.0268 1 10.6406 2.38615 10.6406 4.09606C10.6406 5.80597 12.0268 7.19214 13.7367 7.19214Z"
      fill="#FF4438"
      stroke="#222222"
      strokeWidth="1.58671"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46.5726 37.8249C48.2825 37.8249 49.6687 36.4388 49.6687 34.7289C49.6687 33.019 48.2825 31.6328 46.5726 31.6328C44.8627 31.6328 43.4766 33.019 43.4766 34.7289C43.4766 36.4388 44.8627 37.8249 46.5726 37.8249Z"
      fill="#FF4438"
      stroke="#222222"
      strokeWidth="1.58671"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M55.1526 62.7608L55.1228 62.7767L39.3133 71.6721L23.347 80.6529L4.98681 90.9824L4.82812 91.0716L4.84202 90.9626L6.59731 77.6441L8.18402 65.5455L9.93733 52.2568L11.6411 39.333L12.365 33.8351C15.1814 29.67 27.0421 32.772 38.8531 40.7631C40.8493 42.1102 42.7756 43.558 44.6248 45.1007C52.9233 52.0287 57.4731 59.3295 55.1526 62.7608Z"
      fill="#FF4438"
      stroke="#222222"
      strokeWidth="1.58671"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M44.6243 45.1007C33.8101 42.2604 22.777 40.3311 11.6406 39.333L12.3646 33.8351C15.181 29.67 27.0416 32.772 38.8527 40.7631C40.8488 42.1102 42.7752 43.558 44.6243 45.1007Z"
      fill="#63AEC9"
      stroke="#222222"
      strokeWidth="1.58671"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M55.1221 62.7836L39.3125 71.6791C29.1216 68.8139 18.719 66.7633 8.20312 65.5465L9.95643 52.2578C25.3773 53.9542 40.5401 57.4879 55.1221 62.7836Z"
      fill="#63AEC9"
      stroke="#222222"
      strokeWidth="1.58671"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.3409 80.6572L4.98072 90.9867L4.83594 90.9669L6.59123 77.6484C12.2201 78.4061 17.8033 79.409 23.3409 80.6572Z"
      fill="#63AEC9"
      stroke="#222222"
      strokeWidth="1.58671"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default PartyHat
