import { Box, styled, Typography, useTheme } from '@mui/material'
import { graphql, Link, useStaticQuery } from 'gatsby'
import React, { useCallback } from 'react'
import { HBox, VBox } from '../elements/basic-elements'
import { pageState } from '../context/page-context'
const StaticPageLinks = ({
  vertical,
  renderLink
}: {
  vertical?: boolean
  renderLink?: (label?: string, href: string, target: string) => React.ReactNode
}) => {
  const data = useStaticQuery<Queries.PageQueryQuery>(graphql`
    query PageQuery {
      allSanityPage(filter: { nav_item: { eq: true } }, sort: { nav_order: ASC }) {
        nodes {
          slug {
            current
          }
          nav_order
          nav_label
        }
      }
    }
  `)
  const { navCollapsed, collapseNav } = pageState()

  const navItems = (data.allSanityPage.nodes as Queries.SanityPage[])
    .map(({ slug, nav_label }) => ({ slug: slug.current, label: nav_label }))
    .flat() as Queries.SanityPage[]

  const NavItem = useCallback(
    ({ label, href, target = '_self' }: { label: string; href: string; target?: string }) => {
      return renderLink ? (
        renderLink(label, href, target)
      ) : (
        <Link to={href} target={target} style={{ textDecoration: 'none' }}>
          <Typography variant="caption" color="textSecondary" lineHeight={0} fontSize={12}>
            {label}
          </Typography>
        </Link>
      )
    },
    []
  )

  return (
    <NavItems gap={2}>
      <Box
        display="flex"
        gap={vertical ? 0 : navCollapsed ? 1 : 2}
        justifyContent={vertical ? 'flex-start' : 'flex-end'}
        width="100%"
        alignItems={vertical ? 'flex-start' : navCollapsed ? 'center' : 'flex-end'}
        flexDirection={vertical || navCollapsed ? 'column' : 'row'}
      >
        {navItems.map(navItem => (
          <NavItem label={navItem.label} href={`/${navItem.slug}`} />
        ))}
        <NavItem label="Shop" href="https://shop.importantnotimportant.com" target="_blank" />
      </Box>
    </NavItems>
  )
}

const NavItems = styled(Box)`
  ${({ theme }) => `
    display: flex;
    gap: ${theme.spacing(2)};
    align-items: flex-end;
    padding-top: ${theme.spacing(1)};
    ${theme.breakpoints.down('md')}{
      padding-top: 0;
    }
  `}
`

export default StaticPageLinks
