import { Box, CircularProgress, Typography, styled, useTheme } from '@mui/material'
import React, { ReactNode, useCallback, useEffect, useLayoutEffect } from 'react'
import { colors } from '../constants/colors'
import { pageState } from '../context/page-context'
import { createEmptyArray } from '../utils'
import { isInBrowser } from '../utils/browser'
import { sitePaddingHorz } from '../constants'
import PaginationDots from './pagination-dots'

import { VBox } from '../elements/basic-elements'

let lastScroll = 0
let lastWheel = 0
let blockWheel = false
const pageOffset = 100
const ResultsList = ({
  results,
  title,
  loading,
  prevPage,
  nextPage,
  fetchNextPage,
  onPageChange,
  children
}: {
  results?: {}[]
  title?: string
  loading: boolean
  prevPage?: number
  nextPage?: number
  fetchNextPage?: Function
  onPageChange?: Function
  children?: (data: any) => ReactNode
}) => {
  const cardsRef = React.useRef<HTMLElement>(null)
  const { pageContentRef } = pageState()
  const { navCollapsed, collapseNav } = pageState()
  const [captureMovement, setCaptureMovement] = React.useState(false)
  const [activePage, setActivePage] = React.useState(0)
  const [tempOffset, setTempOffset] = React.useState(0)
  const [cardsPerPage, setCardsPerPage] = React.useState(1)
  const [cardsCanAnimate, setCardsCanAnimate] = React.useState(false)

  const totalResults = results?.length || 0

  useEffect(() => {
    if (results && !cardsCanAnimate) {
      setCardsCanAnimate(true)
    }
  }, [results, cardsCanAnimate])

  const setPageSize = () => {
    if (isInBrowser()) {
      const { innerWidth } = window
      if (navCollapsed) {
        const newCardsPerPage =
          innerWidth >= theme.breakpoints.values.lg
            ? 3
            : innerWidth >= theme.breakpoints.values.md
            ? 2
            : 1
        setCardsPerPage(newCardsPerPage)
      } else {
        const newCardsPerPage =
          innerWidth >= theme.breakpoints.values.xl
            ? 3
            : innerWidth >= theme.breakpoints.values.lg ||
              (innerWidth < theme.breakpoints.values.md &&
                innerWidth >= theme.breakpoints.values.sm)
            ? 2
            : 1
        setCardsPerPage(newCardsPerPage)
      }
    }
  }
  useLayoutEffect(() => {
    setPageSize()
    if (isInBrowser()) {
      window.addEventListener('resize', setPageSize)
      return () => window.removeEventListener('resize', setPageSize)
    }
  }, [navCollapsed])

  const theme = useTheme()
  const numPages = Math.ceil(totalResults / cardsPerPage)
  const oneCard = numPages === 1

  const handleTouchStart = (e: TouchEvent) => {
    // e.preventDefault()
    lastScroll = e.touches[0].clientX
    setCaptureMovement(true)
  }

  const handleTouchMove = (e: TouchEvent) => {
    // e.preventDefault()
    trackMovement(e.touches[0].clientX)
  }

  const handleMouseDown = (e: MouseEvent) => {
    // e.preventDefault()
    lastScroll = e.clientX
    setCaptureMovement(true)
  }
  const handleMoveEnd = () => {
    setCaptureMovement(false)
    setTempOffset(0)
  }
  const handlePageLeft = useCallback(() => {
    const lastPage = activePage >= numPages - 1
    const nextShown = (activePage + 2) * cardsPerPage
    const hasShownAllResults = nextShown > results.length
    const isOverScrolling = nextShown > results.length + 2
    const shouldLoadMore = hasShownAllResults && nextPage && !loading
    if (isOverScrolling) return
    if (shouldLoadMore || !lastPage) {
      setActivePage(activePage + 1)
      if (shouldLoadMore && fetchNextPage) {
        fetchNextPage()
      }
    }
  }, [activePage, numPages, cardsPerPage, loading])

  const handlePageRight = () => {
    if (activePage !== 0) {
      setActivePage(activePage - 1)
    }
  }
  const trackMovement = useCallback(
    (amount: number) => {
      const diff = amount - lastScroll
      if (diff < -25) {
        handleMoveEnd()
        handlePageLeft()
      } else if (diff > 25) {
        handleMoveEnd()
        handlePageRight()
      } else {
        setTempOffset(diff)
      }
    },
    [activePage, numPages, cardsPerPage, loading]
  )

  const handleMove = useCallback(
    (e: MouseEvent) => {
      e.preventDefault()
      trackMovement(e.clientX)
    },
    [activePage, numPages, cardsPerPage, loading]
  )

  const handleWheel = useCallback(
    (e: WheelEvent) => {
      if (blockWheel) {
        if (Math.abs(e.deltaX) < 5) {
          blockWheel = false
        } else {
          return
        }
      }
      if (e.deltaX < -25) {
        blockWheel = true
        handlePageRight()
      } else if (e.deltaX > 25) {
        blockWheel = true
        handlePageLeft()
      }
    },
    [activePage, numPages, cardsPerPage, loading]
  )

  React.useEffect(() => {
    setTempOffset(0)
    const cards = Array.from(cardsRef.current?.children || []) as HTMLElement[]
    cards[activePage]?.focus()
    requestAnimationFrame(() => pageContentRef?.current?.scroll(0, 0))
  }, [cardsRef, activePage, cardsPerPage, pageContentRef])

  React.useEffect(() => {
    // this is to fix a bug where users scrolling very fast could
    // get the parent container to scroll its contents
    if (onPageChange) {
      onPageChange()
    }
  }, [activePage])

  React.useEffect(() => {
    setActivePage(0)
  }, [cardsPerPage])

  const getIndeces = (startingIndex: number, arrayLength: number) => {
    return arrayLength > 0 ? createEmptyArray(arrayLength).map((_i, i) => startingIndex + i) : []
  }

  const numResults = results?.length ?? 0
  const numActiveCards = Math.min(numResults - activePage * cardsPerPage, cardsPerPage)
  const activeIndeces = getIndeces(activePage * cardsPerPage, numActiveCards)

  const numCardsToTheLeft = Math.min(activeIndeces[0] || numResults - 1, cardsPerPage + 1)
  const numCardsToTheRight = Math.min(
    numResults - activeIndeces[activeIndeces.length - 1],
    cardsPerPage + 1
  )
  const leftIndeces = getIndeces(activePage * cardsPerPage - numCardsToTheLeft, numCardsToTheLeft)
  const rightIndeces = getIndeces(activePage * cardsPerPage + cardsPerPage, numCardsToTheRight)
  const getPosition = (index: number) => {
    const cardOffset = pageOffset / cardsPerPage
    const isLeftOfLeft = leftIndeces.length > 0 && index < leftIndeces[0]
    const rightBase = pageOffset
    let position = 0
    const maxLeftOffset =
      totalResults > cardsPerPage && activeIndeces[activeIndeces.length - 1] === totalResults - 1
        ? (cardsPerPage - activeIndeces.length) * cardOffset
        : 0

    if (isLeftOfLeft) {
      position = (leftIndeces.length + 1) * -cardOffset + maxLeftOffset
    }
    const isRightOfRight = rightIndeces.length > 0 && index > rightIndeces[rightIndeces.length - 1]
    if (isRightOfRight) {
      position = rightBase + cardOffset * rightIndeces.length
    }
    const leftIndex = leftIndeces.indexOf(index)
    if (leftIndex > -1) {
      position = (leftIndeces.length - leftIndex) * -cardOffset + maxLeftOffset
    }
    const activeIndex = activeIndeces.indexOf(index)
    if (activeIndex > -1) {
      position = activeIndex * cardOffset + maxLeftOffset
    }
    const rightIndex = rightIndeces.indexOf(index)
    if (rightIndex > -1) {
      position = rightBase + cardOffset * rightIndex
    }
    return position + tempOffset / 2
  }

  return (
    <Results
      onTouchStart={handleTouchStart}
      onTouchMove={!oneCard && captureMovement ? handleTouchMove : undefined}
      onTouchEnd={handleMoveEnd}
      onMouseDown={handleMouseDown}
      onMouseMove={!oneCard && captureMovement ? handleMove : undefined}
      onMouseUp={handleMoveEnd}
      onWheel={handleWheel}
    >
      {title && (
        <Typography variant="h4" color="textSecondary" textAlign="center">
          {title}
        </Typography>
      )}

      <CardsContainer>
        <Cards ref={cardsRef}>
          {results?.map((data, index) => {
            const position = getPosition(index)

            return (
              <Card
                key={index}
                tabIndex={1}
                onFocus={() => {
                  setActivePage(index)
                }}
                offsetLeft={position}
                onePage={numPages === 1}
                only={numResults === 1}
                perPage={cardsPerPage}
                canAnimate={cardsCanAnimate}
                onKeyDown={(e: KeyboardEvent) => {
                  if (e.key === 'ArrowLeft') {
                    if (activePage !== 0) {
                      setActivePage(activePage - 1)
                    }
                  } else if (e.key === 'ArrowRight') {
                    if (activePage !== numPages - 1) {
                      setActivePage(activePage + 1)
                    }
                  }
                }}
              >
                <CardContainerOuter>
                  <CardContainerInner>
                    {children && children(data)}
                    {(loading || nextPage) && index === results.length - 1 && (
                      <ProgressBox>
                        <CircularProgress />
                      </ProgressBox>
                    )}
                  </CardContainerInner>
                </CardContainerOuter>
              </Card>
            )
          })}
        </Cards>
      </CardsContainer>
      {numPages > 1 && (
        <DotsContainer>
          <PositionedDots>
            <PaginationDots dots={numPages} activeIndex={activePage} />
          </PositionedDots>
        </DotsContainer>
      )}
    </Results>
  )
}

const Results = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  touch-action: none;
`

const CardsContainer = styled(Box)`
  position: relative;
  width: 100%;
  left: 0;
  flex: 1;
`
const DotsContainer = styled(Box)`
  position: relative;
  width: 100%:
`
const PositionedDots = styled(Box)`
  position: absolute;
  left: 50%;
  transform: translate(-50%, calc(50% - 20px));
`

const Cards = styled(Box)`
  position: relative;
  width: auto;
  min-width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  align-items: stretch;
  transition: transform 0.2s cubic-bezier(0, 0, 0, 1);
`
const Card = styled(Box)<{
  offsetLeft: number
  only?: boolean
  onePage?: boolean
  canAnimate?: boolean
  perPage?: number
}>`
  position: ${({ only }) => (only ? 'relative' : 'absolute')};
  ${({ only }) => (only ? 'margin: auto;' : '')}
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  outline: none;
  ${({ canAnimate }) => canAnimate && `transition: left 0.5s cubic-bezier(0.49, 0.63, 0, 0.99);`}
  ${({ theme, perPage, offsetLeft, only }) => {
    return `
      left: ${offsetLeft}%;
      flex: 0 0 100%;
      padding: ${theme.spacing(sitePaddingHorz * 0.25)};
      max-width: 100%;
      cursor: ${only ? 'initial' : 'grab'};

      ${theme.breakpoints.down('md')} {
        padding: ${theme.spacing(1)} ${theme.spacing(sitePaddingHorz * 0.125)} ${theme.spacing(2)};
      }
      ${
        perPage == 2 &&
        `
        flex: 0 0 50%;
        max-width: 50%;
      `
      } 
      ${
        perPage == 3 &&
        `
        flex: 0 0 33.33%;
        max-width: 33.33%;
      `
      } 
    `
  }}
`

const CardContainerOuter = styled(Box)`
  position: relative;
  flex: 1;
`
const CardContainerInner = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
`

const ProgressBox = styled(VBox)`
  ${({ theme }) => `
    position: absolute;
    left: calc(100% +  ${theme.spacing(2)});
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 50%;
    align-items: center;
    justify-content: center;
    border-radius: ${theme.shape.borderRadius};
    background: ${theme.palette.mode == 'dark' ? colors.DARK_MODE_80 : colors.WHITE};
  `}
`

export default ResultsList
