import * as React from 'react'
import { PropsWithChildren, useContext, useState } from 'react'
import { isInBrowser } from '../utilities/browser'
import { useMediaQuery, useTheme } from '@mui/material'
import { useLocation } from 'react-router-dom'

export enum ColorScheme {
  COMMUNITY = 'community',
  ACTIONS = 'actions'
}

export const PageContext = React.createContext<{
  pageRef?: React.RefObject<HTMLDivElement>
  pageContentRef?: React.RefObject<HTMLDivElement>
  colorScheme: ColorScheme
  navCollapsed?: boolean
  isMobile?: boolean
  setColorScheme?: React.Dispatch<React.SetStateAction<ColorScheme>>
  collapseNav: React.Dispatch<React.SetStateAction<boolean>>
}>({
  colorScheme: ColorScheme.ACTIONS,
  collapseNav: () => {}
})

export const PageProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [colorScheme, setColorScheme] = useState(ColorScheme.ACTIONS)
  const [navCollapsed, collapseNav] = useState(false)
  const pageRef = React.createRef<HTMLDivElement>()
  const pageContentRef = React.createRef<HTMLDivElement>()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const value = React.useMemo(
    () => ({
      pageRef,
      pageContentRef,
      colorScheme,
      isMobile,
      navCollapsed,
      collapseNav,
      setColorScheme
    }),
    [pageRef, pageContentRef, navCollapsed, colorScheme]
  )

  return <PageContext.Provider value={value}>{children}</PageContext.Provider>
}

export const pageState = () => useContext(PageContext)
