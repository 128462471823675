import { Box, Button, styled, Typography, useMediaQuery, useTheme } from '@mui/material'
import { format, parse } from 'date-fns'
import * as React from 'react'
import { colors } from '../../constants/colors'
import { GrayBox, HBox, SquareButton, VBox } from '../../elements/basic-elements'
import ArrowRight from '../../images/icons/arrow-right'

import { ScrollCard } from '../../elements/cards'
import { T3 } from '../../elements/text-elements'
import GlobalIcon from '../../images/icons/global-icon'
import HeartIcon from '../../images/icons/heart-icon'
import HeartIconFilled from '../../images/icons/heart-icon-filled'
import profilePlaceholder from '../../images/profile-placeholder.png'
import { blocksToText } from '../../utils/sanity'
import { ShareButtonIcon } from '../share-button'
import { Avatar } from '../user-avatar'
import { InfoBox, LocationBox } from '.'
import { pageState } from '../../context/page-context'

const CandidateCard: React.FC<{
  data: Queries.SanityCandidate
  detail?: boolean
  onDetailClick?: Function
}> = ({ data, detail, onDetailClick }) => {
  const candidateImage = data.image?.asset?.url
  const { isMobile } = pageState()
  //   TODO: Add back in when favoriting candidates is supported
  //   const {favorites, updateFavorite} = authState()

  const actionIsFavorited = false
  // favorites && favorites.indexOf(data.slug?.current) > -1

  const HeartButton = React.useCallback(
    () => (
      <SquareButton
        variant="contained"
        color="primary"
        disableElevation
        onClick={() => {
          // updateFavorite(data.slug?.current, !actionIsFavorited)
        }}
      >
        {actionIsFavorited ? <HeartIconFilled /> : <HeartIcon />}
      </SquareButton>
    ),
    [actionIsFavorited]
  )

  const theme = useTheme()
  const isDesktop = !isMobile

  return (
    <ScrollCard elevation={0}>
      {() => ({
        content: (
          <VBox gap={2} flex={1}>
            <Box
              gap={2}
              sx={{
                flexDirection: isDesktop ? 'row' : 'column',
                alignItems: isDesktop ? 'flex-start' : 'center',
                textAlign: isDesktop ? 'left' : 'center'
              }}
            >
              <HBox justifyContent="space-between" alignItems="center">
                {data.jurisdiction && (
                  <LocationBox type={'state'}>
                    <T3 color="textPrimary">{data.jurisdiction}</T3>
                  </LocationBox>
                )}

                <Box>
                  <ShareButtonIcon />
                </Box>
              </HBox>
              <HBox mt={2} gap={2}>
                {candidateImage && <Avatar src={candidateImage} size={100} />}

                <VBox flex={1} justifyContent="space-between" alignItems="flex-start">
                  <VBox>
                    <Typography textAlign="left" color="textSecondary" variant="h3">
                      {data.name}
                    </Typography>

                    {data.office && (
                      <Typography textAlign="left" color="textSecondary" fontSize={15}>
                        Candidate for {data.office ?? ''}
                      </Typography>
                    )}
                  </VBox>
                  {data.election_date && (
                    <Typography textAlign="left" color="info.dark" element="p" fontSize={12}>
                      Election Date:{' '}
                      {format(parse(data.election_date, 'yyyy-MM-dd', new Date()), 'MM/dd/yyyy')}
                    </Typography>
                  )}
                </VBox>
              </HBox>
            </Box>
            <VBox flex={1} gap={2} justifyContent="start">
              {data.history && data.history.length > 0 && (
                <InfoBox>
                  <Typography color="textSecondary" variant="h6">
                    Political History
                  </Typography>
                  <VBox flexWrap="wrap" gap={0.5}>
                    {data.history.map(({ office, jurisdiction, timespan }) => {
                      return (
                        <Typography fontSize={13} color="textSecondary">
                          <b>{office}</b>{' '}
                          {timespan ? (
                            <Typography fontSize={13} color={'info.dark'} component="span">
                              {timespan}
                            </Typography>
                          ) : (
                            ''
                          )}
                          {jurisdiction ? (
                            <Typography fontSize={13} color="textSecondary">
                              {jurisdiction}
                            </Typography>
                          ) : (
                            ''
                          )}
                        </Typography>
                      )
                    })}
                  </VBox>
                </InfoBox>
              )}
              {data.endorsements && (
                <Box>
                  <Typography variant="body2" mb={1} color="textSecondary">
                    Endorsed by:
                  </Typography>
                  <HBox gap={1} alignItems="center" flexWrap="wrap">
                    {data.endorsements.map(endorsement => {
                      const logo = endorsement?.organization?.logo?.asset?.url
                      return (
                        <GrayBox>
                          <HBox gap={1} alignItems="center">
                            {logo && (
                              <img
                                style={{
                                  width: 18,
                                  height: 18,
                                  objectFit: 'cover',
                                  borderRadius: 9,
                                  overflow: 'hidden'
                                }}
                                src={logo}
                              />
                            )}
                            <T3>{endorsement?.name}</T3>
                          </HBox>
                        </GrayBox>
                      )
                    })}
                  </HBox>
                </Box>
              )}
              {data.issues && (
                <Box>
                  <Typography variant="body2" mb={1} color="textSecondary">
                    Issues supported:
                  </Typography>

                  <HBox gap={1} alignItems="center" flexWrap="wrap">
                    {data.issues?.map(issue => {
                      return (
                        <GrayBox>
                          <HBox gap={1} alignItems="center">
                            <T3>{issue?.title}</T3>
                          </HBox>
                        </GrayBox>
                      )
                    })}
                  </HBox>
                </Box>
              )}

              {/* for detail card */}
              <Box>
                {blocksToText(data.about).map(block => (
                  <Box mb={1.2}>
                    <T3 color="textSecondary">{block}</T3>
                  </Box>
                ))}
              </Box>
            </VBox>
          </VBox>
        ),
        footer: (
          <HBox>
            {detail ? (
              <HBox gap={1} width={'100%'}>
                <Button
                  variant="contained"
                  disableElevation
                  color={'primary'}
                  endIcon={<GlobalIcon />}
                  sx={{ flex: 1 }}
                  onClick={() => {
                    data.url && window.open(data.url, '_blank')
                  }}
                >
                  Open Website
                </Button>
              </HBox>
            ) : (
              <HBox gap={1} width={'100%'} justifyContent="flex-end">
                {/* <HeartButton /> */}
                <SquareButton
                  variant="contained"
                  color={'secondary'}
                  disableElevation
                  onClick={() => {
                    onDetailClick && onDetailClick()
                  }}
                >
                  <ArrowRight color={colors.WHITE} />
                </SquareButton>
              </HBox>
            )}
          </HBox>
        )
      })}
    </ScrollCard>
  )
}

export default CandidateCard
