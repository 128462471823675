import {Box, styled} from '@mui/material'
import React from 'react'
import {colors} from '../constants/colors'
import {createEmptyArray} from '../utils'

const dotSize = 10
const dotPadding = 2

const PaginationDots: React.FC<{
  dots: number
  activeIndex: number
  dotsToShow?: number
}> = ({dots, activeIndex, dotsToShow = 11}) => {
  const dotArray = createEmptyArray(dots).map((_, i) => i)
  const zeroIndex = Math.floor(dotsToShow / 2)
  const shiftIndex = zeroIndex + 1

  const lotsOfDots = dots > dotsToShow

  const indexToStopShiftingOn = dots - dotsToShow

  const atBeginning = activeIndex < shiftIndex
  const atEnd = activeIndex - shiftIndex >= indexToStopShiftingOn

  const startingIndex = atBeginning ? 0 : activeIndex - shiftIndex + 1

  const shownDots = lotsOfDots
    ? atEnd
      ? dotArray.slice(
          indexToStopShiftingOn,
          indexToStopShiftingOn + dotsToShow
        )
      : dotArray.slice(startingIndex, startingIndex + dotsToShow)
    : dotArray

  const shifts = shownDots.map((_, i, a) => {
    return i - zeroIndex
  })
  const hiddenPosition = shifts[shifts.length - 1] + 1

  const dotPositions = lotsOfDots
    ? dotArray.map((i) => {
        const shownIndex = shownDots.findIndex((index) => index === i)
        const shift = shifts[shownIndex]
        const direction = i < activeIndex ? -1 : 1
        return shift !== undefined ? shift : hiddenPosition * direction
      })
    : dotArray.map((_) => 0)

  const dotScales = lotsOfDots
    ? dotArray.map((i) => {
        const distanceFromActive = Math.abs(activeIndex - i)
        const foundIndex = shownDots.findIndex((index) => index === i)
        const isHidden = foundIndex === -1
        const isLast = atBeginning
          ? foundIndex === shownDots.length - 1
          : atEnd
          ? foundIndex === 0
          : distanceFromActive === zeroIndex
        const isSecondToLast = atBeginning
          ? foundIndex === shownDots.length - 2
          : atEnd
          ? foundIndex === 1
          : distanceFromActive === zeroIndex - 1
        return isHidden ? 0 : isSecondToLast ? 0.66 : isLast ? 0.33 : 1
      })
    : dotArray.map((_) => 1)

  return (
    <Pagination>
      {dotArray.map((_, i) => {
        const isActive = i === activeIndex

        const position = dotPositions[i] * dotSize
        const scale = dotScales[i]
        const left = lotsOfDots && 'calc(50% - 3px)'
        const margin = lotsOfDots && dotPadding
        const transform = `translateX(${position}px) scale(${scale})`

        const style = {
          position: lotsOfDots ? 'absolute' : 'relative',
          transform,
          left: left ?? '',
          margin: margin ?? '',
          background: isActive && colors.GEORGIAN_BAY_100,
        }

        return <PaginationDot style={style} />
      })}
    </Pagination>
  )
}

const Pagination = styled(Box)`
  width: 100%;
  position: relative;
  display: flex;
  gap: ${dotPadding * 2}px;
  justify-content: center;
  padding: ${({theme}) => theme.spacing(1)} 0;
`

const PaginationDot = styled(Box)`
  width: ${dotSize - dotPadding * 2}px;
  height: ${dotSize - dotPadding * 2}px;
  border-radius: ${dotSize / 2}px;
  background: ${colors.GEORGIAN_BAY_70};
  transition: transform 0.2s cubic-bezier(0, 0, 0, 1);
`

export default PaginationDots
