import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  Theme,
  styled,
  useTheme
} from '@mui/material'
import { MUIStyledCommonProps } from '@mui/system'
import React, { ReactNode } from 'react'
import { colors } from '../constants/colors'
import { HBox } from '../elements/basic-elements'
import CloseIcon from '../images/icons/close-icon'
import { getFlatIconButtonStyle } from '../utils/mui'

type InputProps = {
  id?: string
  hasValue: boolean
  isEditing?: boolean
  disableClose?: boolean
  onCloseClick?: Function
  inputIcon?: JSX.Element
  icon?: JSX.Element
  loading?: boolean
  error?: string
  label?: string
  background?: string
  children: (props: { Input: typeof StyledInput; TextBox: typeof InputWithoutLine }) => ReactNode
}
const InputComponent: React.FC<InputProps> = ({
  id,
  label,
  hasValue,
  isEditing,
  onCloseClick,
  disableClose,
  icon,
  inputIcon,
  loading,
  error,
  background,
  children
}) => {
  const hasError = Boolean(error)
  const hasLabel = Boolean(label)
  const theme = useTheme()
  // don't love that isEditing is controlled outside of component
  return (
    <InputWrapper background={background} variant="filled" error={hasError}>
      <Box flex={1} position="relative">
        {(loading || hasError || hasLabel) && (
          <StyledInputLabel htmlFor={id} classes={{ root: 'root' }}>
            {loading ? (
              <CircularProgress color="tertiary" sx={{ height: 24, width: 24 }} size="sm" />
            ) : (
              error || label
            )}
          </StyledInputLabel>
        )}
        {children({
          TextBox: TextboxWithoutLine,
          Input: StyledInput
        })}
      </Box>
      <IconBox>
        {!isEditing && inputIcon && !hasValue && <IconRight>{inputIcon}</IconRight>}
        {icon && <IconRight>{icon}</IconRight>}
        {(disableClose ? isEditing && hasValue : hasValue) && (
          <CloseButton
            sx={{
              ...getFlatIconButtonStyle('tertiary.main', colors.BLACK_100),
              width: 36,
              height: 36,
              marginRight: '-4px'
            }}
            onClick={() => {
              if (onCloseClick) {
                onCloseClick()
              }
              requestAnimationFrame(() => document.body.focus())
            }}
          >
            <CloseIcon />
          </CloseButton>
        )}
      </IconBox>
    </InputWrapper>
  )
}

export const InputWrapper = styled(FormControl)<{ background?: string }>`
  position: relative;
  min-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(0.5)};
  ${({ theme, background }) => `
    padding: ${theme.spacing(2)};
    border-radius: ${theme.shape.borderRadius};
    padding-top: 0;
    padding-bottom: 0;
    background: ${
      background || (theme.palette.mode == 'dark' ? colors.DARK_MODE_50 : colors.WHITE)
    };
  `}
`
const IconBox = styled(HBox)``

const IconRight = styled(Box)`
  z-index: 1;
  display: flex;
`
const CloseButton = styled(IconButton)`
  width: 20px;
  height: 20px;
  top: 50%;
  z-index: 1;
`
export const inputStyles = (theme: Theme) => `
  width: 100%;
  display: inline-block;
  border: none;
  outline: none;
  background: none;
  user-select: none;
  font-weight: 500;
  padding: 0;
  font-weight: 500;
  font-size: 17px;
  flex: 1;
  // margin-top: 0!important;
  padding: 8px 0!important;
  color: ${theme.palette.mode == 'dark' ? colors.WHITE : colors.BLACK_100};
  ::placeholder {
    color: ${theme.palette.mode == 'dark' ? colors.DARK_MODE_30 : colors.BLACK_50};
  }
`
const StyledTextBox = styled(Input)`
  ${({ theme }) => inputStyles(theme)}
`

const StyledInput = styled('input')`
  ${({ theme }) => inputStyles(theme)}
`

const TextboxWithoutLine = (props: MUIStyledCommonProps<Theme>) => (
  <StyledTextBox disableUnderline {...props} />
)

const StyledInputLabel = styled(InputLabel)`
  ${({ theme }) => `
    &.root {
      color: ${theme.palette.mode == 'dark' ? theme.palette.text.primary : theme.palette.info.dark};
      transform: translate(0, ${theme.spacing(2.75)}) scale(1);
      transition: all 200ms cubic-bezier(0.0, 0, 0.2, 1);


      &[data-shrink='true'] {
        transform: translate(0, ${theme.spacing(1)}) scale(0.75);
        // opacity: 0;

        ${theme.breakpoints.down('md')} {
          transform: translate(0, ${theme.spacing(1)}) scale(0.75);
        }
      }
      &.Mui-error {
          color: ${theme.palette.error.main};
      }

  `}
`

export default InputComponent
