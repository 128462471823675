import { IosShare } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import * as React from 'react'
import { triggerShare } from '../utils/browser'
import TooltipTitle from './tooltip-title'

let showTextTimeout
export const ShareButtonIcon = ({ text }: { text?: string }) => {
  const [showCopiedText, setShowCopiedText] = React.useState(false)

  React.useEffect(() => {
    showTextTimeout = setTimeout(() => setShowCopiedText(false), 2000)
    return () => {
      clearTimeout(showTextTimeout)
    }
  }, [showCopiedText])

  return (
    <Tooltip
      placement="top"
      title={<TooltipTitle description={`Link copied`} />}
      PopperProps={{
        open: showCopiedText
      }}
      disableHoverListener
      sx={{
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <IconButton
        color="secondary"
        onClick={async () => {
          const copied = await triggerShare(text)
          if (copied) {
            setShowCopiedText(true)
          }
        }}
      >
        <IosShare />
      </IconButton>
    </Tooltip>
  )
}
