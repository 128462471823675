export const createEmptyArray = (length: number) => Array.apply(null, Array(length))

export const shuffleArray = (array: any[]) => {
  let currentIndex = array.length,
    randomIndex

  while (currentIndex > 0) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--
    ;[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
  }

  return array
}
export const hasValue = (value: any) => value !== null && value !== undefined && value !== ''

export const extractSimpleUrl = (url: string) => {
  const regex = /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/gim
  const match = regex.exec(url)
  return match && match[1]
}

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
export const isValidEmail = (email: string) => emailRegex.test(email)

export const stringExists = (str?: string) => str && str !== '' && str !== ' '

export const addSentenceStructureToStringArray = (stringArray?: string[], andOrText?: string) => {
  const strings = stringArray?.map((string, i) => {
    const shouldAddAndOr = stringArray.length > 1 && i === stringArray.length - 1
    const shouldAddComma = stringArray.length > 2 && i < stringArray.length - 2
    const isLast = i === stringArray.length - 1
    const andOrTextString = shouldAddAndOr ? (andOrText || '') + ' ' : ''
    const returnString = `${andOrTextString}${string}${shouldAddComma ? ',' : ''}${
      isLast ? '' : ' '
    }`

    return returnString
  })

  return strings
}
