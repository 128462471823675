import React from 'react'

const ArrowRight = ({color}: {color?: string}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="20"
    viewBox="0 0 26 20"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5355 2.61336C15.145 2.22283 15.145 1.58967 15.5355 1.19914C15.926 0.808619 16.5592 0.808619 16.9497 1.19914L25.043 9.29248C25.2306 9.48001 25.3359 9.73437 25.3359 9.99958C25.3359 10.2648 25.2306 10.5192 25.043 10.7067L16.9497 18.8C16.5592 19.1905 15.926 19.1905 15.5355 18.8C15.145 18.4095 15.145 17.7763 15.5355 17.3858L21.9213 11H1.66938C1.11709 11 0.669376 10.5523 0.669376 10C0.669376 9.44771 1.11709 9 1.66938 9H21.9221L15.5355 2.61336Z"
      fill={color || '#232222'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5355 2.61336C15.145 2.22283 15.145 1.58967 15.5355 1.19914C15.926 0.808619 16.5592 0.808619 16.9497 1.19914L25.043 9.29248C25.2306 9.48001 25.3359 9.73437 25.3359 9.99958C25.3359 10.2648 25.2306 10.5192 25.043 10.7067L16.9497 18.8C16.5592 19.1905 15.926 19.1905 15.5355 18.8C15.145 18.4095 15.145 17.7763 15.5355 17.3858L21.9213 11H1.66938C1.11709 11 0.669376 10.5523 0.669376 10C0.669376 9.44771 1.11709 9 1.66938 9H21.9221L15.5355 2.61336Z"
      fill={color || '#232222'}
    />
  </svg>
)

export default ArrowRight
