import { styled } from '@mui/material'
import React from 'react'

const HeartIconFilled = ({color}:{color?:string}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <Path color={color} d="M24.5,4.7c-1.4-0.7-2.9-0.9-4.6-0.5c-1.3,0.3-2.7,1.1-3.9,2.3c-1.2-1.2-2.6-2-3.9-2.3C10.5,3.8,8.9,4,7.5,4.7
	C4.8,6.1,3,9.3,3,12.8c0,7,5.3,10.8,8.7,13.8c0.6,0.5,1.3,1.1,2,1.6c0.7,0.4,1.5,0.8,2.4,0.8s1.7-0.4,2.4-0.8c0.7-0.4,1.3-1,2-1.6
	c3.4-3,8.7-6.8,8.7-13.8C29,9.3,27.2,6.1,24.5,4.7z"/>
  </svg>
)

const Path = styled('path')<{color?:string}>`
  fill: ${({color}) => color || 'white'};
`

export default HeartIconFilled
