import * as React from 'react'
import { PropsWithChildren } from 'react'
import { ActionFilters } from '../types/filter-types'
import { attempt } from '../async/utils'
import { fetchFilters } from '../async/sanity'

export type Filters = {
  allFilters?: ActionFilters
  filtersError?: string
  filtersLoading?: boolean
  fetchAllFiltersIfNotFetched: Function
}

export const FiltersContext = React.createContext<Filters>({
  fetchAllFiltersIfNotFetched: () => {}
})

export const FiltersProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [allFilters, setFilters] = React.useState<ActionFilters>()
  const [filtersError, setFiltersError] = React.useState<string>()
  const [filtersLoading, setFiltersLoading] = React.useState(false)
  // get filters
  const getFilters = React.useCallback(async () => {
    setFiltersLoading(true)
    await attempt(
      async () => {
        await fetchFilters(allFilters, setFilters)
      },
      err => setFiltersError(err || 'Failed to fetch filters')
    )

    setFiltersLoading(false)
  }, [])

  const fetchAllFiltersIfNotFetched = React.useCallback(() => {
    if (!allFilters && !filtersLoading) {
      getFilters()
    }
  }, [allFilters, filtersLoading])
  const value = React.useMemo(
    () => ({
      allFilters,
      filtersLoading,
      filtersError,
      fetchAllFiltersIfNotFetched
    }),
    [allFilters, filtersLoading, filtersError]
  )

  return <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>
}

export const filtersState = () => React.useContext(FiltersContext)
