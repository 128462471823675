export const isInBrowser = () => typeof window !== 'undefined'

export const isTouchDevice = () => {
  return (
    isInBrowser() &&
    ('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0)
  )
}

export const isDarkMode = () =>
  isInBrowser() && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches

export const getInitialColorMode = () => {
  if (!isInBrowser()) return 'light'
  const persistedColorPreference = window.localStorage.getItem('color-mode')
  const hasPersistedPreference = typeof persistedColorPreference === 'string'
  // If the user has explicitly chosen light or dark,
  // let's use it. Otherwise, this value will be null.
  if (hasPersistedPreference) {
    return persistedColorPreference
  }
  // If they haven't been explicit, let's check the media
  // query
  const mql = window.matchMedia('(prefers-color-scheme: dark)')
  const hasMediaQueryPreference = typeof mql.matches === 'boolean'
  if (hasMediaQueryPreference) {
    return mql.matches ? 'dark' : 'light'
  }
  // If they are using a browser/OS that doesn't support
  // color themes, let's default to 'light'.
  return 'light'
}

export const getDomain = () => {
  if (!isInBrowser()) return
  const url = new URL(window.location.href)
  return `${url.protocol}//${url.hostname}${url.port ? `:${url.port}` : ''}`
}
