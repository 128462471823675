export const createSearchableString = (str?: string | null) => {
  return str?.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase() || ''
}

export const stringExists = (str?: string) => str && str !== '' && str !== ' '

export const slugify = (input: string) =>
  input
    .trim()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^a-zA-Z0-9\-]/g, '')
    .slice(0, 200) as string

export const randomHash = (length: number) => {
  const set = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'b',
    'c',
    'd',
    'f',
    'g',
    'h',
    'l',
    'm',
    'n',
    'p',
    'q',
    'r',
    's',
    't',
    'v',
    'w',
    'x',
    'z'
  ]

  const s = new Set()
  while (s.size < length) {
    const n = set[Math.floor(Math.random() * set.length)]
    if (!s.has(n)) {
      s.add(n)
    }
  }
  return Array.from(s).join('')
}

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
export const isValidEmail = (email: string) => emailRegex.test(email)
