import React from 'react'
import { Box, Button, styled, Typography, useTheme } from '@mui/material'

import ChipList, { ChipProps } from './chip-list'
import { resultsState } from '../context/results-context'
import { sitePaddingVert } from '../constants'
import { navigate } from 'gatsby'
import { ActionPill } from './action-input'
import { colors } from '../constants/colors'

const SearchResultsExploreHeader: React.FC = () => {
  const {
    exploreTopics,
    selectedExploreTopic,
    setSelectedExploreTopic,
    updateExploreTopicActions
  } = resultsState()

  const chips = exploreTopics?.map((topic, index) => {
    return {
      title: topic.topic.title as string,
      active: selectedExploreTopic?.topic?.title === exploreTopics![index].topic.title
    }
  })

  const NEW_SEARCH_TITLE = 'Start new search'

  const onChipClick = async ({ title }: ChipProps) => {
    if (title == NEW_SEARCH_TITLE) {
      navigate('/')
    } else if (title !== selectedExploreTopic?.topic.title) {
      const newExploreTopic = exploreTopics?.find(topic => topic.topic.title === title)
      setSelectedExploreTopic(newExploreTopic)
      await updateExploreTopicActions(newExploreTopic?.query)
    }
  }

  const theme = useTheme()
  const overscrollPadding = theme.breakpoints.down('md') ? theme.spacing(6) : theme.spacing(10)
  return (
    <HeadingContainer alignItems="center">
      {chips && (
        <ChipList
          flat={true}
          overscrollPadding={overscrollPadding}
          gap={1}
          items={[
            {
              title: NEW_SEARCH_TITLE,
              active: false
            },
            ...chips
          ]}
          renderChip={({ title, active }) => (
            <Button
              variant="text"
              sx={{ p: 0 }}
              color="secondary"
              onClick={() => onChipClick({ title, active })}
            >
              <ExploreTopic active={active}>
                <Typography variant="h6" sx={{ color: 'current' }}>
                  {title}
                </Typography>
              </ExploreTopic>
            </Button>
          )}
        />
      )}
    </HeadingContainer>
  )
}

const HeadingContainer = styled(Box)`
  ${({ theme }) => `
    padding: ${theme.spacing(sitePaddingVert)} ${theme.spacing(5)} ${theme.spacing(1)};

    ${theme.breakpoints.down('md')} {
      padding: 0 ${theme.spacing(3)}
    }
  `}
`

const ExploreTopic = styled(ActionPill)<{ active?: boolean }>`
  white-space: nowrap;
  ${({ theme, active }) => `
    ${
      theme.palette.mode == 'dark'
        ? `
      ${
        active
          ? `
        background: ${colors.DARK_MODE_70};
      `
          : ``
      }
    `
        : `
        :hover,
        .Mui-focusVisible & {
          background: ${colors.GEORGIAN_BAY_70};
        }
      ${
        active
          ? `
          background: ${colors.GEORGIAN_BAY_70};
          color: ${colors.WHITE};
        `
          : ``
      } 
    `
    }

  `}
`

export default SearchResultsExploreHeader
