import React, { MouseEventHandler } from 'react'
import { ArrowRight } from '@mui/icons-material'
import { Box, Button, Typography, styled, useTheme } from '@mui/material'
import { T3 } from '../../elements/text-elements'
import OutreachIcon from '../../images/icons/outreach-icon'
import RepresentativePhoto from './representative-photo'
import { HBox, VBox } from '../../elements/basic-elements'

const RepresentativeButton: React.FC<{
  name: string
  title: string
  photo?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
}> = ({ name, title, photo, onClick }) => {
  const theme = useTheme()
  return (
    <StyledButton
      color={theme.palette.mode == 'dark' ? 'secondary' : 'info'}
      variant="contained"
      disableElevation
      endIcon={<ArrowRight />}
      onClick={onClick}
    >
      <HBox flex={1} alignItems="center" gap={1}>
        <RepresentativePhoto size={6} photo={photo} />
        <VBox alignItems="flex-start">
          <Typography variant="body1" fontWeight="bold" color="textSecondary">
            {name}
          </Typography>
          <T3 variant="body2" color="textSecondary">
            {title}
          </T3>
        </VBox>
      </HBox>
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  ${({ theme }) => `
    padding: ${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(1)};
  `}
`

export default RepresentativeButton
