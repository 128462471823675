import { Badge, Box, CircularProgress, IconButton, Typography, styled } from '@mui/material'
import * as React from 'react'
import { colors } from '../constants/colors'
import { actionDataState } from '../context/action-data-context'
import { candidateDataState } from '../context/candidate-data-context'
import { resultsState } from '../context/results-context'
import FilterIcon from '../images/icons/filter-icon'
import FilterControls from './filter-controls'
import FullScreenModal from './full-screen-modal'

const FilterBox = () => {
  const { actions, totalActions = 0 } = actionDataState()
  const { totalCandidates = 0 } = candidateDataState()
  const { filters, loading } = resultsState()
  const [showFilterModal, setShowFilterModal] = React.useState(false)

  const activeFilters = [
    filters.verbsExclusive && 1,
    filters.nounsExclusive && 1,
    filters.cobenefitsExclusive && 1,
    filters.endorsementsExclusive && 1,
    filters.issuesExclusive && 1,
    filters.verbs && filters.verbs.length,
    filters.nouns && filters.nouns?.length,
    filters.cobenefits && filters.cobenefits?.length,
    filters.levels && filters.levels?.length,
    filters.sorts && filters.sorts?.length,
    filters.endorsements && filters.endorsements?.length,
    filters.issues && filters.issues?.length
  ].filter(exists => Boolean(exists))

  const filterCount = (activeFilters as number[]).reduce((a: number, b: number) => a + b, 0)

  const totalResults = totalActions + totalCandidates
  const plural = totalResults === 1 ? '' : 's'
  const activeFilterText =
    totalResults === 0 ? 'No Active Filters' : totalResults + `Active Filter${plural}`

  const closeModal = () => {
    setShowFilterModal(false)
  }

  return (
    <FilterBoxElement>
      <Typography flex={1} color="textSecondary" variant="body2" textAlign="center">
        {loading ? (
          <CircularProgress size={12} color="secondary" />
        ) : (
          <React.Fragment>
            {totalResults} Result
            {plural}
          </React.Fragment>
        )}
      </Typography>
      <StyledBadge color="primary" badgeContent={filterCount}>
        <StyledIconButton
          aria-label={`Search Filters - ${activeFilterText}`}
          onClick={() => setShowFilterModal(true)}
        >
          <FilterIcon />
        </StyledIconButton>
      </StyledBadge>

      <FullScreenModal
        open={showFilterModal}
        colorScheme="grey"
        contentPadding={false}
        onClose={closeModal}
      >
        <FilterControls onSave={closeModal} />
      </FullScreenModal>
    </FilterBoxElement>
  )
}

const StyledBadge = styled(Badge)`
  pointer-events: none;
`

const StyledIconButton = styled(IconButton)`
  ${({ theme }) => {
    const background = theme.palette.secondary.main
    const color = colors.GEORGIAN_BAY_100
    const padding = theme.spacing(0.5)
    return `
      background-color: ${background};
      border-radius: 9px;
      pointer-events: all;
      color: ${color};
      padding: ${padding};
      &:hover {
        background-color: ${background};
      };
  `
  }}
`

const FilterBoxElement = styled(Box)`
  ${({ theme }) => {
    const paddingLeft = theme.spacing(1.5)
    return `
        border-radius: 9px;
        display: inline-flex;
        align-items: center;
        white-space: nowrap;
        gap: ${paddingLeft};
        padding: 0 0 0 ${paddingLeft};

        background: ${theme.palette.mode == 'dark' ? colors.DARK_MODE_70 : colors.WHITE};
    `
  }}
`

export default FilterBox
