import { PaletteMode, ThemeProvider, createTheme } from '@mui/material'
import * as React from 'react'
import { FC, PropsWithChildren } from 'react'

import { colors } from '../constants/colors'
import { colorModeContext } from './color-context'
import { authState } from './auth-context'

const placeholder = createTheme({})
const { breakpoints } = placeholder

const getDesignTokens = (mode: PaletteMode) => ({
  typography: {
    fontFamily: 'HCo Gotham',
    fontSize: 12,
    body1: {
      fontWeight: 400,
      fontSize: 17,
      lineHeight: 1.35,
      letterSpacing: 0
    },
    body2: {
      fontWeight: 400,
      fontSize: 15,
      lineHeight: 1.4,
      letterSpacing: 0
    },
    h1: {
      fontFamily: 'Fields',
      fontWeight: 900,
      fontSize: 58,
      lineHeight: 1.02,
      letterSpacing: '-0.02em',
      [breakpoints.down('md')]: {
        fontSize: 42
      }
    },
    h2: {
      fontWeight: 500,
      fontSize: 40,
      lineHeight: 1.05,
      letterSpacing: '-0.03em',
      [breakpoints.down('md')]: {
        fontSize: '20px'
      }
    },
    h3: {
      fontWeight: 500,
      fontSize: 34,
      lineHeight: 1.05,
      letterSpacing: '-0.03em'
    },
    h4: {
      fontWeight: 500,
      fontSize: 28,
      lineHeight: 1.1,
      letterSpacing: '-0.03em',
      [breakpoints.down('md')]: {
        fontSize: '17px'
      }
    },
    h5: {
      fontWeight: 500,
      fontSize: 24,
      lineHeight: 1.1,
      letterSpacing: '-0.03em'
    },
    h6: {
      fontWeight: 500,
      fontSize: 20,
      lineHeight: 1.1,
      letterSpacing: 0,
      [breakpoints.down('md')]: {
        fontSize: '15px'
      }
    },
    caption: {
      textTransform: 'uppercase',
      fontSize: 12,
      fontWeight: 500
    }
  },
  shape: {
    borderRadius: '15px'
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 24,
          textTransform: 'none',
          minWidth: 'auto',
          padding: `${placeholder.spacing(1.5)} ${placeholder.spacing(2.5)}`
        },
        fullWidth: {
          padding: placeholder.spacing(2)
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: `${placeholder.spacing(1)} ${placeholder.spacing(2)}`,
          color: mode === 'light' ? colors.BLACK_100 : colors.DARK_MODE_100,
          background: mode === 'light' ? colors.WHITE : colors.DARK_MODE_30,
          boxShadow: 'rgba(0,0,0,1) 0 0 15px -10px',
          margin: '6px!important',
          whiteSpace: 'pre-wrap'
        },
        arrow: {
          color: mode === 'light' ? colors.BLACK_50 : colors.DARK_MODE_30
        }
      }
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          background: mode == 'dark' ? colors.DARK_MODE_70 : colors.WHITE
        }
      }
    }
  },
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          primary: {
            main: colors.RED_100
          },
          secondary: {
            main: colors.GLACIER_BLUE_100
          },
          tertiary: {
            main: colors.WHITE
          },
          info: {
            main: colors.BLACK_30
          },
          text: {
            primary: colors.WHITE,
            secondary: colors.BLACK_100
          },
          success: {
            main: colors.GLACIER_BLUE_100,
            contrastText: colors.WHITE
          },
          warning: {
            main: '#ffa22a'
          }
        }
      : {
          // palette values for dark mode
          primary: {
            main: colors.RED_100
          },
          secondary: {
            main: colors.DARK_MODE_50
          },
          tertiary: {
            main: colors.DARK_MODE_70
          },
          info: {
            main: colors.DARK_MODE_10
          },
          text: {
            primary: colors.WHITE,
            secondary: colors.WHITE
          },
          success: {
            main: colors.DARK_MODE_30,
            contrastText: colors.DARK_MODE_80
          },
          warning: {
            main: '#c27e4d'
          }
        })
  }
})

const Theme: FC<PropsWithChildren> = ({ children }) => {
  const { mode } = colorModeContext()
  // Update the theme only if the mode changes
  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode])

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['primary']
  }

  interface PaletteOptions {
    tertiary?: PaletteOptions['primary']
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true
  }
}
declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    tertiary: true
  }
}

export default Theme
