import { Box, styled } from '@mui/material'
import { navigate } from 'gatsby'
import * as React from 'react'
import { resultsState } from '../context/results-context'
import { convertFiltersToParamObject, parameterizeFilters } from '../utils/data'
import FullScreenModal from './full-screen-modal'
import ResultCard from './result-card'
import ResultsList from './result-list'
import { candidateDataState } from '../context/candidate-data-context'
import CandidateCard from './result-card/candidate-card'
import { SmallText } from '../elements/form-elements'

const CandidatesList = ({ title, onPageChange }: { title?: string; onPageChange?: Function }) => {
  const { candidates, loading, prevPage, nextPage, fetchNextPage } = candidateDataState()

  const {
    filters,
    activeCandidate,
    showResultModal,
    modalWasClickedInto,
    setModalWasClickedInto,
    setActiveCandidate
  } = resultsState()

  return (
    <Container>
      <ResultsList
        title={title}
        results={candidates}
        loading={Boolean(loading)}
        prevPage={prevPage}
        nextPage={nextPage}
        fetchNextPage={fetchNextPage}
        onPageChange={onPageChange}
      >
        {data => {
          const candidate = data as Queries.SanityCandidate
          return (
            <CandidateCard
              data={candidate}
              onDetailClick={() => {
                setModalWasClickedInto(true)
                setActiveCandidate(candidate)
                navigate(`/candidate/${candidate.slug?.current}`)
              }}
            />
          )
        }}
      </ResultsList>
      <SmallText pt={2}>*Candidate data may be incomplete and is updated weekly</SmallText>
      <FullScreenModal
        contentPadding={true}
        open={Boolean(activeCandidate && showResultModal)}
        onClose={() => {
          if (modalWasClickedInto) {
            navigate(-1)
          } else {
            // this is happening if user landed on a specific action
            navigate(`/results/?${parameterizeFilters(convertFiltersToParamObject(filters))}`)
          }
        }}
      >
        {activeCandidate && <CandidateCard data={activeCandidate} detail={true} />}
      </FullScreenModal>
    </Container>
  )
}

const Container = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
`
export default CandidatesList
